import { createContext } from "react";
import { observable, action, runInAction } from "mobx";

import { cropSuggestionApi } from "../api/CropSuggestionApi";
import { CropSuggestionDto } from "../types/CropSuggestion";
import { States } from "./State";

class CropSuggestionStore {
	@observable
	cropSuggestion: CropSuggestionDto[] = [];

	@observable
	cropSuggestionState: States = States.INITIAL;

	@action.bound
	resetCropSuggestionState(): void {
		this.cropSuggestionState = States.INITIAL;
	}

	@action.bound
	getCropSuggestionByLandId(id: number) {
		this.cropSuggestionState = States.PENDING;
		cropSuggestionApi
			.getCropSuggestionByLandId(id)
			.then((cropSuggestion) => {
				runInAction(() => {
					cropSuggestion = cropSuggestion.sort(
						(a, b) => a.priority - b.priority
					);
					this.cropSuggestion = cropSuggestion;
					this.cropSuggestionState = States.DONE;
				});
			})
			.catch((error) => {
				runInAction(() => {
					this.cropSuggestionState = States.ERROR;
					console.log(error);
				});
			});
	}
}

export const CropSuggestionStoreContext = createContext(
	new CropSuggestionStore()
);
