import { createContext } from "react";
import { observable, action } from "mobx";

import { Locales } from "../i18n/Locales";

export class LanguageStore {
	@observable
	language: Locales = Locales.HUNGARIAN;

	@action.bound
	setLanguage(lang: Locales) {
		this.language = lang;
	}
}

export const LanguageStoreContext = createContext(new LanguageStore());
