import React, { useContext } from "react";
import { Navbar, Nav, Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { GiFarmer } from "react-icons/gi";
import { IconContext } from "react-icons";
import ReactFlagsSelect from "react-flags-select";
import "react-flags-select/css/react-flags-select.css";
import { defineMessages, FormattedMessage } from "react-intl";
import { observer } from "mobx-react";

import { LanguageStore } from "../../stores/LanguageStore";
import { Locales } from "../../i18n/Locales";
import { LoginStoreContext } from "../../stores/LoginStore";
import { States } from "../../stores/State";
import { FailedAlert } from "../FailedAlert";

type Props = {
	languageState: LanguageStore;
};

const messages = defineMessages({
	newData: {
		id: "navigation.newData",
		defaultMessage: "New data",
	},
	landList: {
		id: "navigation.landList",
		defaultMessage: "Land list",
	},
	title: {
		id: "navigation.title",
		defaultMessage: "Agriary project",
	},

	updateUserData: {
		id: "navigation.updateUserData",
		defaultMessage: "Update my data",
	},
	logout: {
		id: "navigation.logout",
		defaultMessage: "Log out",
	},
});

export const NavigationBar: React.FC<Props> = observer(({ languageState }) => {
	const state = useContext(LoginStoreContext);
	const loggedIn = state.loggedIn;
	// if state isn't stored in variable, won't rerender the page when state changes
	const logOutState = state.logOutState;

	const onSelectFlag = (countryCode: string) => {
		countryCode === "GB"
			? languageState.setLanguage(Locales.ENGLISH)
			: languageState.setLanguage(Locales.HUNGARIAN);
	};

	const handleAlert = () => {
		state.resetLogoutState();
	};

	return (
		<div>
			<Navbar bg="light" variant="light">
				<Navbar.Brand as={Link} to="/">
					<IconContext.Provider
						value={{
							color: "primary",
							className: "global-class-name",
							size: "35px",
						}}
					>
						<GiFarmer />
					</IconContext.Provider>
					<FormattedMessage {...messages.title} />
				</Navbar.Brand>
				{loggedIn && (
					<>
						<Navbar.Toggle aria-controls="responsive-navbar-nav" />
						<Navbar.Collapse id="responsive-navbar-nav">
							<Nav className="mr-auto">
								<Nav.Link as={Link} to="/newland">
									<FormattedMessage {...messages.newData} />
								</Nav.Link>
								<Nav.Link as={Link} to="/lands">
									<FormattedMessage {...messages.landList} />
								</Nav.Link>
								<Nav.Link as={Link} to="/updateUserData">
									<FormattedMessage {...messages.updateUserData} />
								</Nav.Link>
							</Nav>
						</Navbar.Collapse>
					</>
				)}

				{loggedIn && (
					<Button
						onClick={() => {
							state.logOut();
						}}
					>
						<FormattedMessage {...messages.logout} />
					</Button>
				)}

				<ReactFlagsSelect
					countries={["GB", "HU"]}
					customLabels={{ GB: "English", HU: "Magyar" }}
					defaultCountry="HU"
					onSelect={onSelectFlag}
				/>
			</Navbar>
			<Container>
				<Row className="justify-content-md-center mt-5">
					<Col lg="6" xs>
						{logOutState === States.ERROR && (
							<FailedAlert onHide={handleAlert} />
						)}
					</Col>
				</Row>
			</Container>
		</div>
	);
});
