import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import { observer } from "mobx-react";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Image from "react-bootstrap/Image";
import { ButtonGroup, Container, Row } from "react-bootstrap";
import { defineMessages, useIntl, FormattedMessage } from "react-intl";

import { LandStoreContext } from "../stores/LandStore";
import { formStyles } from "./styles/Styles";
import garlic from "../resources/images/landProfiles/garlic.jpg";
import maize from "../resources/images/landProfiles/maize.jpg";
import potato from "../resources/images/landProfiles/potato.jpg";
import rice from "../resources/images/landProfiles/rice.jpg";
import tomato from "../resources/images/landProfiles/tomato.jpg";
import wheat from "../resources/images/landProfiles/wheat.jpg";
import tractor from "../resources/images/landProfiles/tractor.jpg";
import { Land, LandDto } from "../types/Land";
import { convertArea } from "../utils/UnitFormatters";
import { commonMessages } from "../i18n/CommonMessages";
import { States } from "../stores/State";
import { SuccesfulAlert } from "./SuccesfulAlert";
import { FailedAlert } from "./FailedAlert";

export const images = {
	garlic: garlic,
	maize: maize,
	potato: potato,
	rice: rice,
	tomato: tomato,
	wheat: wheat,
	tractor: tractor,
};

type Props = {
	land?: Land;
};

const messages = defineMessages({
	landName: {
		id: "landForm.landName",
		defaultMessage: "Land name",
	},
	landId: {
		id: "landForm.landId",
		defaultMessage: "Land cadastre number",
	},
	landUnit: {
		id: "landForm.landUnit",
		defaultMessage: "Unit",
	},
	landAreaForm: {
		id: "landForm.landAreaForm",
		defaultMessage: "Land area",
	},
	selectImage: {
		id: "landForm.selectImage",
		defaultMessage: "Select image",
	},
	selectedImage: {
		id: "landForm.selectedImage",
		defaultMessage: "Selected images",
	},
	selectImageTitle: {
		id: "landForm.selectImageTitle",
		defaultMessage: "Select image from this land",
	},
	newLandButton: {
		id: "landForm.newLandButton",
		defaultMessage: "Add new land",
	},
	editLandButton: {
		id: "landForm.editLandButton",
		defaultMessage: "Edit land",
	},
	landNamePlaceholder: {
		id: "landForm.landNamePlaceholder",
		defaultMessage: "Enter your land name",
	},
	landIdPlaceholder: {
		id: "landForm.landIdPlaceholder",
		defaultMessage: "Enter your land cadastre number",
	},
});

export const LandForm: React.FC<Props> = observer(({ land }) => {
	const state = useContext(LandStoreContext);

	// if state isn't stored in variable, won't rerender the page when state changes
	const newLandState = state.newLandState;

	const [showModal, setShowModal] = useState(false);
	const [selectedImage, setSelectedImage] = useState(
		land ? land.imageName : ""
	);
	const [unit, setUnit] = useState(
		land ? (land.area > 1000 ? "ha" : "m2") : "ha"
	);
	const [changedUnit, setChanged] = useState(false);

	const handleClose = () => {
		setShowModal(false);
		setSelectedImage("");
	};

	const handleShow = () => setShowModal(true);

	const clickImage = (imageName: string) => {
		setSelectedImage(imageName);
	};

	const showImages = Object.entries(images).map(([imageName, imageSource]) => (
		<Image
			className={selectedImage.match(imageName) ? "withBorder" : "img"}
			key={imageName}
			onClick={() => clickImage(imageName)}
			src={imageSource}
		/>
	));

	const isDisabled = (values: LandDto): boolean => {
		return land
			? (land.area > 1000
					? values.area === land.area / 10000
					: values.area === land.area) &&
					values.name === land.name &&
					values.landId === land.landId &&
					values.imageName === land.imageName &&
					!changedUnit
			: false;
	};

	const handleAlert = () => {
		state.resetNewLandState();
	};

	const { formatMessage } = useIntl();

	const formik = useFormik({
		initialValues: {
			name: land ? land.name : "",
			landId: land ? land.landId : "",
			area: convertArea(land?.area ?? 0),
			imageName: land ? land.imageName : "",
		},
		onSubmit: (values) => {
			values.imageName = selectedImage ? selectedImage : "tractor";
			if (selectedImage === "") {
				values.imageName = "tractor";
			} else {
				values.imageName = selectedImage;
			}
			if (unit === "ha") {
				values.area = values.area * 10000;
			}
			land ? state.updateLand(values, land.id) : state.addNewLand(values);
		},
		validationSchema: Yup.object().shape({
			name: Yup.string()
				.min(
					8,
					formatMessage(commonMessages.tooShortValidation, {
						entity: formatMessage(messages.landName),
						number: 8,
					})
				)
				.required(formatMessage(commonMessages.required)),
			area: Yup.number()
				.positive(formatMessage(commonMessages.validationArea))
				.required(formatMessage(commonMessages.required)),
		}),
	});

	const resetForm = formik.resetForm;
	const dirty = formik.dirty;
	useEffect(() => {
		if (newLandState === States.DONE && dirty) {
			resetForm();
		}
	}, [newLandState, resetForm, dirty]);

	return (
		<>
			<Container>
				<Row className="justify-content-md-center mt-5">
					<Col lg="6" xs>
						{newLandState === States.DONE && (
							<SuccesfulAlert onHide={handleAlert} />
						)}
						{newLandState === States.ERROR && (
							<FailedAlert onHide={handleAlert} />
						)}
					</Col>
				</Row>
			</Container>

			<form action="" onSubmit={formik.handleSubmit} style={formStyles.form}>
				<Form.Group controlId="formGrifFieldName">
					<Form.Label className="font-weight-bold">
						{" "}
						<FormattedMessage {...messages.landName} />{" "}
					</Form.Label>
					<Form.Control
						name="name"
						onBlur={formik.handleBlur}
						onChange={formik.handleChange}
						placeholder={formatMessage(messages.landNamePlaceholder)}
						type="text"
						value={formik.values.name}
					/>
					{formik.errors.name && formik.touched.name && (
						<div className="input-feedback">{formik.errors.name}</div>
					)}
				</Form.Group>

				<Form.Group controlId="formGridCadastreNumber">
					<Form.Label className="font-weight-bold">
						<FormattedMessage {...messages.landId} />
					</Form.Label>
					<Form.Control
						name="landId"
						onBlur={formik.handleBlur}
						onChange={formik.handleChange}
						placeholder={formatMessage(messages.landIdPlaceholder)}
						type="text"
						value={formik.values.landId}
					/>
					{formik.errors.landId && formik.touched.landId && (
						<div className="input-feedback">{formik.errors.landId} </div>
					)}
				</Form.Group>

				<Form.Row>
					<Form.Group as={Col} controlId="formGridArea">
						<Form.Label className="font-weight-bold">
							{" "}
							<FormattedMessage {...messages.landAreaForm} />{" "}
						</Form.Label>
						<Form.Control
							name="area"
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							placeholder={formatMessage(messages.landAreaForm)}
							step="0.01"
							type="number"
							value={formik.values.area}
						/>
						{formik.errors.area && formik.touched.area && (
							<div className="input-feedback">{formik.errors.area}</div>
						)}
					</Form.Group>

					<Form.Group as={Col} controlId="formGridState">
						<Col>
							<Form.Label className="font-weight-bold">
								<FormattedMessage {...messages.landUnit} />
							</Form.Label>
						</Col>
						<Col>
							<ButtonGroup>
								<Button
									onClick={(
										event: React.MouseEvent<HTMLDivElement, MouseEvent>
									) => {
										event.preventDefault();
										setUnit("ha");
										setChanged(true);
									}}
									value="ha"
									variant={unit === "ha" ? "primary" : "secondary"}
								>
									ha
								</Button>
								<Button
									onClick={(
										event: React.MouseEvent<HTMLDivElement, MouseEvent>
									) => {
										event.preventDefault();
										setUnit("m2");
										setChanged(true);
									}}
									value="m2"
									variant={unit === "m2" ? "primary" : "secondary"}
								>
									m²
								</Button>
							</ButtonGroup>
						</Col>
					</Form.Group>
				</Form.Row>

				<Form.Label className="font-weight-bold">
					<FormattedMessage {...messages.selectImageTitle} />
				</Form.Label>
				<Form.Row>
					<Form.Group>
						<Button block onClick={handleShow} variant="outline-primary">
							<FormattedMessage {...messages.selectImage} />
						</Button>
					</Form.Group>
					<Form.Group as={Col}>
						<Form.Control
							disabled
							name="imageName"
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							value={formik.values.imageName}
						/>
						{formik.errors.imageName && formik.touched.imageName && (
							<div className="input-feedback">{formik.errors.imageName}</div>
						)}
					</Form.Group>
				</Form.Row>

				<Form.Row>
					<Form.Group as={Col}>
						<Button
							className="float-right"
							disabled={isDisabled(formik.values)}
							style={formStyles.form}
							type="submit"
							variant="primary"
						>
							{land
								? formatMessage(messages.editLandButton)
								: formatMessage(messages.newLandButton)}
						</Button>
					</Form.Group>
				</Form.Row>

				<Modal onHide={handleClose} show={showModal}>
					<Modal.Header closeButton>
						<Modal.Title>
							<FormattedMessage {...messages.selectImageTitle} />
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>{showImages}</Modal.Body>
					<Modal.Footer>
						<Button onClick={handleClose} variant="secondary">
							<FormattedMessage {...commonMessages.closeButton} />
						</Button>
						<Button
							onClick={() => {
								formik.values.imageName = selectedImage;
								setShowModal(false);
							}}
							variant="primary"
						>
							<FormattedMessage {...messages.selectedImage} />
						</Button>
					</Modal.Footer>
				</Modal>
			</form>
		</>
	);
});
