import { observer } from "mobx-react";
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { LandStoreContext } from "../../stores/LandStore";
import { MapLands } from "../MapLands";
import { setPage } from "../../utils/PaginationUtils";
import { useQuery } from "../../utils/NavigationHooks";
import { PaginationContainer } from "../PaginationContainer";

export const Lands: React.FC = observer(() => {
	const state = useContext(LandStoreContext);

	const history = useHistory();
	const page = useQuery().get("page");
	const url = "/lands?";

	useEffect(() => {
		setPage(state.setCurrentPage, page, history, state.pagination.endPage, url);
		state.getLands();
	}, [state, page, history]);

	return (
		<div>
			<MapLands lands={state.lands} />

			<PaginationContainer pagination={state.pagination} url={url} />
		</div>
	);
});
