import { createContext } from "react";
import { observable, action, runInAction } from "mobx";

import { FinancialReport } from "../types/FinancialReport";
import { ReportFilteringOptions } from "../types/ReportFilteringOptions";
import { financialReportApi } from "../api/FinancialReportApi";
import { States } from "./State";

class FinancialReportStore {
	@observable
	financialReport?: FinancialReport;

	@observable
	financialReportState: States = States.INITIAL;

	@action.bound
	resetFinancialReportState(): void {
		this.financialReportState = States.INITIAL;
	}

	@action.bound
	getReport(reportFilteringOptions: ReportFilteringOptions) {
		this.financialReportState = States.PENDING;
		let fetchPromise: Promise<FinancialReport>;
		if (
			reportFilteringOptions.land !== undefined &&
			reportFilteringOptions.year !== undefined
		) {
			fetchPromise = financialReportApi.getReportForYearAndLand(
				reportFilteringOptions.year,
				reportFilteringOptions.land
			);
		} else if (
			reportFilteringOptions.land === undefined &&
			reportFilteringOptions.year !== undefined
		) {
			fetchPromise = financialReportApi.getReportForYear(
				reportFilteringOptions.year
			);
		} else if (
			reportFilteringOptions.land !== undefined &&
			reportFilteringOptions.year === undefined
		) {
			fetchPromise = financialReportApi.getReportForLand(
				reportFilteringOptions.land
			);
		} else {
			fetchPromise = financialReportApi.getReportForAll();
		}

		fetchPromise
			.then((financialReport) => {
				runInAction(() => {
					this.financialReport = financialReport;
					this.financialReportState = States.DONE;
				});
			})
			.catch((error) => {
				runInAction(() => {
					this.financialReportState = States.ERROR;
					console.log(error);
				});
			});
	}
}

export const FinancialReportStoreContext = createContext(
	new FinancialReportStore()
);
