import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import * as Yup from "yup";
import { useFormik } from "formik";
import { defineMessages, useIntl, FormattedMessage } from "react-intl";
import Image from "react-bootstrap/Image";
import { Container, Row, Col } from "react-bootstrap";

import { formStyles } from "./styles/Styles";
import { ProduceStoreContext } from "../stores/ProduceStore";
import { commonMessages } from "../i18n/CommonMessages";
import RowCrop from "../resources/images/crops/rowCrop.jpg";
import Legumes from "../resources/images/crops/legumes.jpg";
import GreenManure from "../resources/images/crops/greenManure.jpg";
import GrassesAndCereals from "../resources/images/crops/grassesAndCereals.jpg";
import { ProduceCategory } from "../types/Produce";
import { States } from "../stores/State";
import { FailedAlert } from "./FailedAlert";
import { SuccesfulAlert } from "./SuccesfulAlert";

const cropImages = {
	[ProduceCategory.RowCrop]: RowCrop,
	[ProduceCategory.Legumes]: Legumes,
	[ProduceCategory.GrassesAndCereals]: GrassesAndCereals,
	[ProduceCategory.GreenManure]: GreenManure,
};

const messages = defineMessages({
	produceName: {
		id: "produceForm.produceName",
		defaultMessage: "Produce name",
	},
	produceCategory: {
		id: "produceForm.produceCategory",
		defaultMessage: "Produce category",
	},
	produceCode: {
		id: "produceForm.produceCode",
		defaultMessage: "Produce code",
	},
	newProduceButton: {
		id: "produceForm.newProduceButton",
		defaultMessage: "Add new produce",
	},
	produceNamePlaceholder: {
		id: "produceForm.produceNamePlaceholder",
		defaultMessage: "Enter your produce name",
	},
	produceCategoryPlaceholder: {
		id: "produceForm.produceCategoryPlaceholder",
		defaultMessage: "Enter this produce category",
	},
	rowCropInfos: {
		id: "produceForm.rowCropInfos",
		defaultMessage:
			"Every crop that is grown in tight rows. (e.g: corn, sunflower, potato and the vegetables) However these crops are the most profitable for the farmers, they typically have low biomass and shallow roots, so they are more taxing on the soil.",
	},
	legumesInfos: {
		id: "produceForm.legumesInfos",
		defaultMessage:
			" These kind of crops collect the available nitrogen from the atmosphere and store it in their root structure. (e.g. alfalfa, clover, pea, soybean)",
	},
	grassesAndCerealsInfos: {
		id: "produceForm.grassesAndCerealsInfos",
		defaultMessage:
			"They supply to soil quality and structure. The dense and far-reaching root systems give ample structure to surrounding soil and provide significant biomass for soil organic matter. (e.g. grain, rye, rice, weed)",
	},
	greenManureInfos: {
		id: "produceForm.greenManureInfos",
		defaultMessage:
			"This is a crop that is mixed in the soil. Can be used for resting the land.",
	},
});

const produceCategoryInfos = {
	[ProduceCategory.RowCrop]: {
		name: commonMessages.rowCrops,
		description: messages.rowCropInfos,
	},
	[ProduceCategory.Legumes]: {
		name: commonMessages.legumesName,
		description: messages.legumesInfos,
	},
	[ProduceCategory.GrassesAndCereals]: {
		name: commonMessages.grassesAndCerealsName,
		description: messages.grassesAndCerealsInfos,
	},
	[ProduceCategory.GreenManure]: {
		name: commonMessages.greenManureName,
		description: messages.greenManureInfos,
	},
};

export const ProduceForm = observer(() => {
	const state = useContext(ProduceStoreContext);
	// if state isn't stored in variable, won't rerender the page when state changes
	const newProduceState = state.newProduceState;

	const { formatMessage } = useIntl();

	const [selectedCategory, setSelectedCategory] = useState<ProduceCategory>(
		ProduceCategory.RowCrop
	);

	const handleAlert = () => {
		state.resetNewProduceState();
	};

	const categoryImages = Object.entries(
		cropImages
	).map(([imageName, imageSource]) => (
		<Image
			className={
				selectedCategory === parseInt(imageName) ? "withBorder" : "img"
			}
			key={imageName}
			onClick={() => setSelectedCategory(parseInt(imageName))}
			roundedCircle
			src={imageSource}
		/>
	));

	const formik = useFormik({
		initialValues: {
			name: "",
			category: 1,
			code: 0,
		},
		onSubmit: (values) => {
			values.category = selectedCategory;
			state.addNewProduce(values);
		},
		validationSchema: Yup.object().shape({
			name: Yup.string()
				.min(
					3,
					formatMessage(commonMessages.tooShortValidation, {
						entity: formatMessage(messages.produceName),
						number: 3,
					})
				)
				.required(formatMessage(commonMessages.required)),
			code: Yup.number()
				.notRequired()
				.test(
					"code",
					formatMessage(commonMessages.tooShortValidation, {
						entity: formatMessage(messages.produceCode),
						number: 2,
					}),
					function (value) {
						if (value) {
							const schema = Yup.number().min(10);
							return schema.isValidSync(value);
						}
						return true;
					}
				),
		}),
	});

	const resetForm = formik.resetForm;
	const dirty = formik.dirty;
	useEffect(() => {
		if (newProduceState === States.DONE && dirty) {
			resetForm();
		}
	}, [newProduceState, resetForm, dirty]);

	return (
		<>
			<Container>
				<Row className="justify-content-md-center mt-5">
					<Col lg="6" xs>
						{newProduceState === States.DONE && (
							<SuccesfulAlert onHide={handleAlert} />
						)}
						{newProduceState === States.ERROR && (
							<FailedAlert onHide={handleAlert} />
						)}
					</Col>
				</Row>
			</Container>
			<form action="" onSubmit={formik.handleSubmit} style={formStyles.form}>
				<Form.Group controlId="formGridProduceName">
					<Form.Label className="font-weight-bold">
						<FormattedMessage {...messages.produceName} />
					</Form.Label>
					<Form.Control
						name="name"
						onBlur={formik.handleBlur}
						onChange={formik.handleChange}
						placeholder={formatMessage(messages.produceNamePlaceholder)}
						type="text"
						value={formik.values.name}
					/>
					{formik.errors.name && formik.touched.name && (
						<div className="input-feedback">{formik.errors.name}</div>
					)}
				</Form.Group>

				<Form.Group controlId="formGridProduceName">
					<Form.Label className="font-weight-bold">
						<FormattedMessage {...messages.produceCode} />
					</Form.Label>
					<Form.Control
						name="code"
						onBlur={formik.handleBlur}
						onChange={formik.handleChange}
						placeholder={formatMessage(messages.produceCode)}
						type="number"
						value={formik.values.code}
					/>
					{formik.errors.code && formik.touched.code && (
						<div className="input-feedback">{formik.errors.code}</div>
					)}
				</Form.Group>

				<Form.Group controlId="formGridCadastreNumber">
					<Form.Label className="font-weight-bold">
						<FormattedMessage {...messages.produceCategory} />
					</Form.Label>
					<Form.Group>
						{categoryImages}
						<div>
							<h1>
								<FormattedMessage
									{...produceCategoryInfos[selectedCategory].name}
								/>
							</h1>
							<h5>
								<FormattedMessage
									{...produceCategoryInfos[selectedCategory].description}
								/>
							</h5>
						</div>
					</Form.Group>
				</Form.Group>

				<Form.Group>
					<Button
						className="float-right"
						style={formStyles.form}
						type="submit"
						variant="primary"
					>
						<FormattedMessage {...messages.newProduceButton} />
					</Button>
				</Form.Group>
			</form>
		</>
	);
});
