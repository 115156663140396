import { History, LocationState } from "history";

export const setPagination = (
	totalRows: number,
	pageSize: number,
	currentPage: number
) => {
	return {
		totalRows: totalRows,
		pageSize: pageSize,
		currentPage: currentPage,
		endPage: Math.ceil(totalRows / pageSize),
		entitiesOnLastPage:
			totalRows % pageSize === 0 ? pageSize : totalRows % pageSize,
	};
};

export const setPage = (
	setCurrentPage: CallableFunction,
	page: string | null,
	history: History<LocationState>,
	endPage: number,
	url: string
) => {
	if (page === undefined || page === null) {
		setCurrentPage(1);
	} else {
		const pageNumber = +page;
		if (pageNumber > endPage || isNaN(pageNumber) || pageNumber <= 0) {
			history.push(`${url}${endPage}`);
		} else {
			setCurrentPage(pageNumber);
		}
	}
};
