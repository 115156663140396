import { createContext } from "react";
import { observable, action, runInAction } from "mobx";

import { States } from "./State";
import { UserDto } from "../types/User";
import { userApi } from "../api/UserApi";
import { UpdatedUserDto } from "../types/User";

class UserStore {
	@observable
	UsersState: States = States.INITIAL;

	@observable
	newUserState: States = States.INITIAL;

	@observable
	updateUserDataState: States = States.INITIAL;

	@action.bound
	resetUpdateUserDataState(): void {
		this.updateUserDataState = States.INITIAL;
	}

	@action.bound
	resetNewUserState(): void {
		this.newUserState = States.INITIAL;
	}

	@action.bound
	addUser(newUser: UserDto) {
		this.newUserState = States.PENDING;
		userApi
			.postUser(newUser)
			.then(() => {
				runInAction(() => {
					this.newUserState = States.DONE;
				});
			})
			.catch((error) => {
				runInAction(() => {
					this.newUserState = States.ERROR;
					console.log(error);
				});
			});
	}

	@action.bound
	updateUserData(updateUserData: Partial<UpdatedUserDto>) {
		this.updateUserDataState = States.PENDING;
		userApi
			.updateUserData(updateUserData)
			.then(() => {
				runInAction(() => {
					this.updateUserDataState = States.DONE;
				});
			})
			.catch((error) => {
				runInAction(() => {
					this.updateUserDataState = States.ERROR;
					console.log(error);
				});
			});
	}
}

export const UsersStoreContext = createContext(new UserStore());
