import { CropSuggestionDto } from "../types/CropSuggestion";
import { CONSTANTS } from "./Constants";

class CropSuggestionApi {
	async getCropSuggestionByLandId(id: number): Promise<CropSuggestionDto[]> {
		const response = await fetch(
			`${CONSTANTS.API_URL}/lands/${id}/cropSuggestion`,
			{
				method: "GET",
				headers: { "Content-Type": "application/json" },
			}
		);
		return response.json();
	}
}

export const cropSuggestionApi = new CropSuggestionApi();
