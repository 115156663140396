import { defineMessages } from "react-intl";

export const commonMessages = defineMessages({
	undo: {
		id: "common.undo",
		defaultMessage: "Undo",
	},
	goAhead: {
		id: "common.goAhead",
		defaultMessage: "Go ahead!",
	},
	delete: {
		id: "common.delete",
		defaultMessage: "Delete",
	},
	tooShortValidation: {
		id: "common.tooShortValidation",
		defaultMessage:
			"{entity} is too short - please enter at least {number} characters",
	},
	validationArea: {
		id: "common.validationArea",
		defaultMessage: "Not a positive number",
	},
	required: {
		id: "common.required",
		defaultMessage: "This field is required",
	},
	closeButton: {
		id: "common.closeButton",
		defaultMessage: "Close",
	},
	datePlaceholder: {
		id: "common.datePlaceholder",
		defaultMessage: "Select a day",
	},
	date: {
		id: "common.date",
		defaultMessage: "Date",
	},
	choose: {
		id: "common.choose",
		defaultMessage: "Choose...",
	},
	description: {
		id: "common.description",
		defaultMessage: "Description",
	},
	edit: {
		id: "common.edit",
		defaultMessage: "Edit",
	},
	transactions: {
		id: "common.transactions",
		defaultMessage: "Income / Expense",
	},
	requiredStar: {
		id: "common.requiredStar",
		defaultMessage: "Fields marked with * are required",
	},
	login: {
		id: "common.login",
		defaultMessage: "Log in",
	},
	register: {
		id: "common.register",
		defaultMessage: "Register",
	},
	legumesName: {
		id: "common.legumesName",
		defaultMessage: "Legumes",
	},
	grassesAndCerealsName: {
		id: "common.grassesAndCerealsName",
		defaultMessage: "Grasses and cereals",
	},
	greenManureName: {
		id: "common.greenManureName",
		defaultMessage: "Green manure",
	},

	chartText: {
		id: "common.chartText",
		defaultMessage: "Crop rotation distribution",
	},
	rowCrops: {
		id: "common.rowCrops",
		defaultMessage: "Row crop",
	},
	timesPlanted: {
		id: "common.timesPlanted",
		defaultMessage:
			"Produce type an amount of types it was used in crop rotation in last ten years",
	},
	successful: {
		id: "common.successful",
		defaultMessage: "Operation completed successfully",
	},
	failed: {
		id: "common.failed",
		defaultMessage: "Operation failed",
	},
	save: {
		id: "common.save",
		defaultMessage: "Save",
	},
	operationTable: {
		id: "operation.operationTable",
		defaultMessage: "Operation",
	},
	finish: {
		id: "common.finish",
		defaultMessage: "Finish",
	},
});
