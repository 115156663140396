import { observer } from "mobx-react";
import { PieChart } from "react-minimal-pie-chart";
import React, { useContext, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import CSS from "csstype";
import { Container, Row, Col } from "react-bootstrap";

import { LandStoreContext } from "../stores/LandStore";
import { commonMessages } from "../i18n/CommonMessages";
import { ProduceCategory } from "../types/Produce";
import { States } from "../stores/State";
import { FailedAlert } from "./FailedAlert";

const labelStyleCss: CSS.Properties = {
	fontSize: "7px",
};

type Props = {
	chartSize?: [number, number];
};

export const Chart: React.FC<Props> = observer(({ chartSize }) => {
	const state = useContext(LandStoreContext);
	// if state isn't stored in variable, won't rerender the page when state changes
	const countProduceState = state.countProduceState;
	const format = (name: String, category: ProduceCategory) => {
		return (
			formatMessage(commonMessages.timesPlanted) +
			"\n " +
			name +
			" : " +
			produces[category]
		);
	};

	const handleAlert = () => {
		state.resetCountProduceState();
	};

	let { id } = useParams();
	const { formatMessage } = useIntl();
	useEffect(() => {
		state.countProduceByType(id);
	}, [state, id]);

	let produces = state.produceCountByCategory;
	if (
		produces[ProduceCategory.Legumes] +
			produces[ProduceCategory.GrassesAndCereals] +
			produces[ProduceCategory.RowCrop] +
			produces[ProduceCategory.GreenManure] >
		0
	) {
		return (
			<Container>
				<Row>
					<h6>
						<FormattedMessage {...commonMessages.chartText} />
					</h6>
				</Row>
				<Row>
					<PieChart
						animate
						data={[
							{
								title: format(
									formatMessage(commonMessages.legumesName),
									ProduceCategory.Legumes
								),
								value: produces[ProduceCategory.Legumes],
								color: "#ff9900",
							},
							{
								title: format(
									formatMessage(commonMessages.greenManureName),
									ProduceCategory.GreenManure
								),
								value: produces[ProduceCategory.GreenManure],
								color: "#0370E0",
							},
							{
								title: format(
									formatMessage(commonMessages.grassesAndCerealsName),
									ProduceCategory.GrassesAndCereals
								),
								value: produces[ProduceCategory.GrassesAndCereals],
								color: "#E00303",
							},
							{
								title: format(
									formatMessage(commonMessages.rowCrops),
									ProduceCategory.RowCrop
								),
								value: produces[ProduceCategory.RowCrop],
								color: "#399D14",
							},
						]}
						label={({ dataEntry }) => {
							if (Math.round(dataEntry.percentage) > 0) {
								return `${Math.round(dataEntry.percentage)} %`;
							}
						}}
						labelStyle={labelStyleCss}
						viewBoxSize={chartSize ? chartSize : [200, 100]}
					/>
				</Row>
				<Row className="justify-content-md-center mt-5">
					<Col lg="6" xs>
						{countProduceState === States.ERROR && (
							<FailedAlert onHide={handleAlert} />
						)}
					</Col>
				</Row>
			</Container>
		);
	} else {
		return <div />;
	}
});
