import { UserDto } from "../types/User";
import { CONSTANTS } from "./Constants";
import { UpdatedUserDto } from "../types/User";

class UserApi {
	async postUser(User: UserDto): Promise<void> {
		const resp = await fetch(`${CONSTANTS.API_URL}/users`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(User),
		});
		if (!resp.ok) {
			throw new Error("Creating user failed");
		}
	}

	async updateUserData(user: Partial<UpdatedUserDto>): Promise<void> {
		const resp = await fetch(`${CONSTANTS.API_URL}/users/profile`, {
			method: "PATCH",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(user),
		});
		return resp.json();
	}
}

export const userApi = new UserApi();
