import { Button } from "react-bootstrap";
import React from "react";
import { Link, useParams } from "react-router-dom";
import "../styles/Maintain.css";
import { AiFillInfoCircle } from "react-icons/ai";
import { GiPlantsAndAnimals } from "react-icons/gi";
import { FaTractor } from "react-icons/fa";
import { defineMessages, FormattedMessage } from "react-intl";

type Props = {
	activePage?: string;
};

const messages = defineMessages({
	generalInformation: {
		id: "maintain.generalInformation",
		defaultMessage: "General",
	},
	plant: {
		id: "maintain.plant",
		defaultMessage: "Planting and harvesting",
	},
	operation: {
		id: "maintain.operation",
		defaultMessage: "Add operation",
	},
});

export const MaintainNavigation: React.FC<Props> = ({ activePage }) => {
	let { id } = useParams();

	return (
		<div>
			<Link to={"/general/" + id}>
				<Button
					block
					className="iconContext"
					variant={activePage === "general" ? "primary" : "outline-primary"}
				>
					<AiFillInfoCircle className="iconContext" />
					<FormattedMessage {...messages.generalInformation} />
				</Button>
			</Link>
			<Link to={"/plantingHistory/" + id}>
				<Button
					block
					className="iconContext"
					variant={
						activePage === "plantingHistory" ? "primary" : "outline-primary"
					}
				>
					<GiPlantsAndAnimals className="iconContext" />
					<FormattedMessage {...messages.plant} />
				</Button>
			</Link>
			<Link to={"/addOperation/" + id}>
				<Button
					block
					className="iconContext"
					variant={
						activePage === "addOperation" ? "primary" : "outline-primary"
					}
				>
					<FaTractor className="iconContext" />
					<FormattedMessage {...messages.operation} />
				</Button>
			</Link>
		</div>
	);
};
