import { OperationType, OperationTypeDto } from "../types/OperationType";
import { CONSTANTS } from "./Constants";

class OperationTypeApi {
	async getOperationTypes(): Promise<OperationType[]> {
		const response = await fetch(`${CONSTANTS.API_URL}/operationTypes`, {
			method: "GET",
			headers: { "Content-Type": "application/json" },
		});
		return response.json();
	}

	async getOperationTypeById(id: number): Promise<OperationType> {
		const response = await fetch(`${CONSTANTS.API_URL}/operationTypes/${id}`, {
			method: "GET",
			headers: { "Content-Type": "application/json" },
		});
		return response.json();
	}

	async postOperationType(operationType: OperationTypeDto): Promise<void> {
		const response = await fetch(`${CONSTANTS.API_URL}/operationTypes`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(operationType),
		});
		if (!response.ok) {
			throw new Error("Creating operation type failed");
		}
	}
}

export const operationTypeApi = new OperationTypeApi();
