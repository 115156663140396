import { createContext } from "react";
import { observable, action, runInAction } from "mobx";

import { LoginDto } from "../types/User";
import { logInApi } from "../api/LogInApi";
import { States } from "./State";

class LoginStore {
	@observable
	logInState: States = States.INITIAL;
	@observable
	logOutState: States = States.INITIAL;
	@observable
	isLoggedinState: States = States.INITIAL;

	@observable
	loggedIn: boolean = false;

	@action.bound
	resetisLoggedinState(): void {
		this.isLoggedinState = States.INITIAL;
	}

	runIntrospect() {
		this.isLoggedinState = States.PENDING;
		logInApi
			.introspect()
			.then((response) => {
				runInAction(() => {
					if (response.status === "Authorized") {
						console.log("Wellcome :", response.name);
						this.loggedIn = true;
					}

					this.isLoggedinState = States.DONE;
				});
			})
			.catch((error) => {
				runInAction(() => {
					this.isLoggedinState = States.ERROR;
					console.log(error);
				});
			});
	}

	@action.bound
	resetLoginState(): void {
		this.logInState = States.INITIAL;
	}

	@action.bound
	resetLogoutState(): void {
		this.logOutState = States.INITIAL;
	}

	@action.bound
	logIn(user: LoginDto) {
		this.logInState = States.PENDING;
		logInApi
			.authenticate(user)
			.then(() => {
				runInAction(() => {
					this.logInState = States.DONE;
					window.location.reload();
				});
			})
			.catch((error) => {
				runInAction(() => {
					this.logInState = States.ERROR;
					console.log(error);
				});
			});
	}

	@action.bound
	logOut() {
		this.logOutState = States.PENDING;
		logInApi
			.logout()
			.then(() => {
				runInAction(() => {
					this.logOutState = States.DONE;
					window.location.reload(false);
				});
			})
			.catch((error) => {
				runInAction(() => {
					this.logOutState = States.ERROR;
					console.log(error);
				});
			});
	}
}

export const LoginStoreContext = createContext(new LoginStore());
