export const convertArea = (area?: number) =>
	area ? (area > 1000 ? area / 10000 : area) : 0;
export const getDisplayUnit = (area?: number) =>
	area ? (area > 1000 ? "ha" : "m²") : "";

export const displayYieldForm = (myYield: number) =>
	myYield ? (myYield >= 1000 ? myYield / 1000 + " t" : myYield + " kg") : "";

export const convertYield = (produceYield: string, myYield?: number) =>
	produceYield === "kg" ? myYield : myYield ? myYield * 1000 : 0;
