import { Produce, ProduceDto } from "../types/Produce";
import { CONSTANTS } from "./Constants";

class ProduceApi {
	async getProduce(): Promise<Produce[]> {
		const response = await fetch(`${CONSTANTS.API_URL}/produces`, {
			method: "GET",
			headers: { "Content-Type": "application/json" },
		});
		return response.json();
	}

	async postProduce(produce: ProduceDto): Promise<void> {
		const response = await fetch(`${CONSTANTS.API_URL}/produces`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(produce),
		});
		if (!response.ok) {
			throw new Error("Creating produce failed");
		}
	}
}

export const produceApi = new ProduceApi();
