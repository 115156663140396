import React from "react";

import { LandForm } from "../LandForm";
import { NewData } from "../navigation/NewData";

export const NewLand: React.FC = () => {
	return (
		<div>
			<NewData activePage="newland" />
			<LandForm />
		</div>
	);
};
