import React from "react";

import { NewData } from "../navigation/NewData";
import { OperationTypeForm } from "../OperationTypeForm";

export const NewOperationType: React.FC = () => {
	return (
		<div>
			<NewData activePage="operationType" />
			<OperationTypeForm />
		</div>
	);
};
