import { createContext } from "react";
import { observable, action, runInAction } from "mobx";

import { OperationType, OperationTypeDto } from "../types/OperationType";
import { operationTypeApi } from "../api/OperationTypeApi";
import { States } from "./State";

class OperationTypeStore {
	@observable
	operationTypes: OperationType[] = [];

	@observable
	operationType?: OperationType;

	@observable
	operationTypeState: States = States.INITIAL;

	@observable
	operationTypesState: States = States.INITIAL;

	@observable
	newOperationTypeState: States = States.INITIAL;

	@action.bound
	resetNewOperationTypeState(): void {
		this.newOperationTypeState = States.INITIAL;
	}

	@action.bound
	resetOperationTypeState(): void {
		this.operationTypeState = States.INITIAL;
	}

	@action.bound
	resetOperationTypesState(): void {
		this.operationTypesState = States.INITIAL;
	}

	@action.bound
	addNewOperationType(operationType: OperationTypeDto) {
		this.newOperationTypeState = States.PENDING;
		operationTypeApi
			.postOperationType(operationType)
			.then(() => {
				runInAction(() => {
					this.newOperationTypeState = States.DONE;
				});
			})
			.catch((error) => {
				runInAction(() => {
					this.newOperationTypeState = States.ERROR;
					console.log(error);
				});
			});
	}

	@action.bound
	getOperationTypeById(operationTypeId: number) {
		this.operationTypeState = States.PENDING;
		operationTypeApi
			.getOperationTypeById(operationTypeId)
			.then((operationType) => {
				runInAction(() => {
					this.operationType = operationType;
					this.operationTypeState = States.DONE;
				});
			})
			.catch((error) => {
				runInAction(() => {
					this.operationTypeState = States.ERROR;
					console.log(error);
				});
			});
	}

	@action.bound
	getOperationTypes() {
		this.operationTypes = [];
		this.operationTypesState = States.PENDING;
		operationTypeApi
			.getOperationTypes()
			.then((operationTypes) => {
				runInAction(() => {
					this.operationTypes = operationTypes;
					this.operationTypesState = States.DONE;
				});
			})
			.catch((error) => {
				runInAction(() => {
					this.operationTypesState = States.ERROR;
					console.log(error);
				});
			});
	}
}

export const OperationTypeStoreContext = createContext(
	new OperationTypeStore()
);
