import { Land, LandDto, StatisticsDto } from "../types/Land";
import { CONSTANTS } from "./Constants";

class LandApi {
	async getLands(page: number, pageSize: number): Promise<Land[]> {
		const response = await fetch(
			`${CONSTANTS.API_URL}/lands?page=${page}&pageSize=${pageSize}`,
			{
				method: "GET",
				headers: { "Content-Type": "application/json" },
			}
		);
		return response.json();
	}

	async getAllLands(): Promise<Land[]> {
		const response = await fetch(`${CONSTANTS.API_URL}/lands`, {
			method: "GET",
			headers: { "Content-Type": "application/json" },
		});
		return response.json();
	}

	async getTotalLandsNumber(): Promise<number> {
		const response = await fetch(`${CONSTANTS.API_URL}/lands/total`, {
			method: "GET",
			headers: { "Content-Type": "application/json" },
		});
		return response.json();
	}

	async getLandById(id: number): Promise<Land> {
		const response = await fetch(`${CONSTANTS.API_URL}/lands/${id}`, {
			method: "GET",
			headers: { "Content-Type": "application/json" },
		});
		return response.json();
	}

	async postLand(land: LandDto): Promise<void> {
		const response = await fetch(`${CONSTANTS.API_URL}/lands`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(land),
		});
		if (!response.ok) {
			throw new Error("Creating land failed");
		}
	}

	async deleteLand(id: number): Promise<void> {
		const response = await fetch(`${CONSTANTS.API_URL}/lands/${id}`, {
			method: "DELETE",
			headers: { "Content-Type": "application/json" },
		});
		if (!response.ok) {
			throw new Error("Deleting land failed");
		}
	}

	async patchLand(land: LandDto): Promise<void> {
		const response = await fetch(`${CONSTANTS.API_URL}/lands`, {
			method: "PATCH",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(land),
		});
		if (!response.ok) {
			throw new Error("Patching land failed");
		}
	}

	async updateLand(land: Partial<LandDto>, landId: number): Promise<void> {
		const response = await fetch(`${CONSTANTS.API_URL}/lands/${landId}`, {
			method: "PATCH",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(land),
		});
		if (!response.ok) {
			throw new Error("Updating land failed");
		}
	}
	async getProduceTypeCount(landId: number): Promise<StatisticsDto> {
		const number = await fetch(
			`${CONSTANTS.API_URL}/lands/${landId}/categoryStatistics`,
			{
				method: "GET",
				headers: { "Content-Type": "application/json" },
			}
		);
		return number.json();
	}
}

export const landApi = new LandApi();
