import React, { useEffect, useContext, useState } from "react";
import { Row, Col, Button, Container } from "react-bootstrap";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Badge from "react-bootstrap/Badge";
import Select, { components, OptionProps, OptionTypeBase } from "react-select";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker, { DayValue } from "react-modern-calendar-datepicker";
import { defineMessages, useIntl, FormattedMessage } from "react-intl";

import { ProduceStoreContext } from "../stores/ProduceStore";
import "./styles/AddNewPlantingHistory.css";
import { States } from "../stores/State";
import { LandStoreContext } from "../stores/LandStore";
import { PlantingHistoryStoreContext } from "../stores/PlantingHistoryStore";
import { CropSuggestionStoreContext } from "../stores/CropSuggestionStore";
import { getDate } from "../utils/DateFormatter";
import { commonMessages } from "../i18n/CommonMessages";
import { ProduceCategory } from "../types/Produce";
import { Chart } from "./Chart";
import { FailedAlert } from "./FailedAlert";
import { SuccesfulAlert } from "./SuccesfulAlert";

const messages = defineMessages({
	addNewHistory: {
		id: "plantingHistoryForm.addNewHistory",
		defaultMessage: "Add new history to {name}",
	},
	plantingHistoryButton: {
		id: "plantingHistoryForm.plantingHistoryButton",
		defaultMessage: "Add new planting history",
	},
	selectProduce: {
		id: "plantingHistoryForm.selectProduce",
		defaultMessage: "Select produce:",
	},
	selectPlantingDate: {
		id: "plantingHistoryForm.selectPlantingDate",
		defaultMessage: "Select the planting date:",
	},
	selectHarvestDate: {
		id: "plantingHistoryForm.selectHarvestDate",
		defaultMessage: "Select the harvest date: ",
	},
	suggestCropType: {
		id: "plantingHistoryForm.suggestCropType",
		defaultMessage: "Suggested type of crops: ",
	},
});

const produceCategoryNames = {
	[ProduceCategory.RowCrop]: commonMessages.rowCrops,
	[ProduceCategory.Legumes]: commonMessages.legumesName,
	[ProduceCategory.GrassesAndCereals]: commonMessages.grassesAndCerealsName,
	[ProduceCategory.GreenManure]: commonMessages.greenManureName,
};

type SelectOption = {
	value: string;
	label: string;
	category: ProduceCategory;
};

export const PlantingHistoryForm: React.FC = observer(() => {
	const plantingHistoryState = useContext(PlantingHistoryStoreContext);
	// if state isn't stored in variable, won't rerender the page when state changes
	const newPlantingHistoryState = plantingHistoryState.newPlantingHistoryState;
	const updatePlantingHistoryState =
		plantingHistoryState.updatePlantingHistoryState;

	const [selectedPlantingDate, setSelectedPlantingDate] = useState<
		DayValue | undefined
	>();

	const [selectedHarvestDate, setSelectedHarvestDate] = useState<
		DayValue | undefined
	>();

	const handleHarvestDate = (selectedHarvestDate: DayValue) =>
		setSelectedHarvestDate(selectedHarvestDate);

	const produceState = useContext(ProduceStoreContext);
	const landState = useContext(LandStoreContext);
	const cropSuggestionState = useContext(CropSuggestionStoreContext);

	// if state isn't stored in variable, won't rerender the page when state changes
	const getLandState = landState.landState;
	const getCropSuggestionState = cropSuggestionState.cropSuggestionState;
	const getProducesState = produceState.producesState;

	const getProduceId = (produceName: string) => {
		return produceState.produces.find(
			(produce) => produce.name === produceName
		);
	};

	let { id } = useParams();

	useEffect(() => {
		produceState.getProduces();
	}, [produceState]);

	useEffect(() => {
		landState.getLandById(id);
	}, [id, landState]);

	useEffect(() => {
		cropSuggestionState.getCropSuggestionByLandId(id);
	}, [id, cropSuggestionState]);

	const { formatMessage } = useIntl();

	const handleNewAlert = () => {
		plantingHistoryState.resetNewPlantingHistoryState();
	};
	const handleUpdateAlert = () => {
		plantingHistoryState.resetUpdatePlantingHistoryState();
	};
	const handleGetLandAlert = () => {
		landState.resetLandState();
	};
	const handleGetCropSuggestionAlert = () => {
		cropSuggestionState.resetCropSuggestionState();
	};
	const handleGetProducesAlert = () => {
		produceState.resetProducesState();
	};

	const suggestionVariantMap: { [key: number]: string } = {
		[ProduceCategory.RowCrop]: "success",
		[ProduceCategory.Legumes]: "warning",
		[ProduceCategory.GrassesAndCereals]: "danger",
	};

	Object.keys(suggestionVariantMap).forEach((key) => {
		if (
			!cropSuggestionState.cropSuggestion
				.map((a) => a.category)
				.includes(Number(key))
		) {
			suggestionVariantMap[Number(key)] = "white";
		}
	});

	function setOptions() {
		const options = [] as SelectOption[];
		if (produceState.producesState === States.DONE) {
			produceState.produces.forEach((produce) => {
				options.push({
					value: produce.name,
					label: produce.name,
					category: produce.category,
				});
			});
		}
		return options;
	}

	const options = setOptions();
	const { Option } = components;

	/* eslint-disable react/jsx-props-no-spreading, react/destructuring-assignment */
	function CustomOption<OptionType extends OptionTypeBase>(
		props: OptionProps<OptionType>
	) {
		return (
			<Option {...props}>
				{props.data.category && (
					<Badge
						className="dot"
						pill
						variant={suggestionVariantMap[props.data.category]}
					>
						{" "}
					</Badge>
				)}
				<span className="select-item-label"> {props.data.label}</span>
			</Option>
		);
	}
	/* eslint-enable react/jsx-props-no-spreading, react/destructuring-assignment */

	const styles = { menu: (styles: any) => ({ ...styles, zIndex: 999 }) };

	const formik = useFormik({
		initialValues: {
			landId: landState.land?.id,
			produceName: "",
			plantingDate: "",
		},
		onSubmit: (values) => {
			let plantingHistory = {
				landId: Number(values.landId),
				produceId: getProduceId(values.produceName)?.id,
				plantingDate: getDate(selectedPlantingDate),
				harvestDate: getDate(selectedHarvestDate),
			};
			plantingHistoryState.addNewPlantingHistory(plantingHistory);
		},
		validationSchema: Yup.object().shape({
			produceName: Yup.string().required(
				formatMessage(commonMessages.required)
			),
			plantingDate: Yup.string()
				.notOneOf(["undefined"], formatMessage(commonMessages.required))
				.required(formatMessage(commonMessages.required)),
		}),
	});
	const resetForm = formik.resetForm;
	const dirty = formik.dirty;
	useEffect(() => {
		if (
			(newPlantingHistoryState === States.DONE ||
				updatePlantingHistoryState === States.DONE) &&
			dirty
		) {
			resetForm();
		}
	}, [newPlantingHistoryState, updatePlantingHistoryState, resetForm, dirty]);

	return (
		<>
			<Container>
				<Row className="justify-content-md-center mt-5">
					<Col lg="6" xs>
						{newPlantingHistoryState === States.DONE && (
							<SuccesfulAlert onHide={handleNewAlert} />
						)}
						{newPlantingHistoryState === States.ERROR && (
							<FailedAlert onHide={handleNewAlert} />
						)}
						{updatePlantingHistoryState === States.DONE && (
							<SuccesfulAlert onHide={handleUpdateAlert} />
						)}
						{updatePlantingHistoryState === States.ERROR && (
							<FailedAlert onHide={handleUpdateAlert} />
						)}
						{getLandState === States.ERROR && (
							<FailedAlert onHide={handleGetLandAlert} />
						)}
						{getCropSuggestionState === States.ERROR && (
							<FailedAlert onHide={handleGetCropSuggestionAlert} />
						)}
						{getProducesState === States.ERROR && (
							<FailedAlert onHide={handleGetProducesAlert} />
						)}
					</Col>
				</Row>
			</Container>
			<form action="" className="operationForm" onSubmit={formik.handleSubmit}>
				<Row>
					<Col>
						<Chart chartSize={[250, 100]} />
					</Col>
					<Col>
						<Form.Label className="titlePosition font-weight-bold float-right">
							<h1>
								<FormattedMessage
									{...messages.addNewHistory}
									values={{
										name:
											landState.landState === States.DONE
												? " " + landState.land?.name
												: null,
									}}
								/>
							</h1>
						</Form.Label>
					</Col>
				</Row>
				<Row>
					<Col>
						<Form.Label className="font-weight-bold">
							<div>
								<FormattedMessage {...messages.suggestCropType} />
							</div>
						</Form.Label>
					</Col>
				</Row>
				<Row>
					<Col>
						{cropSuggestionState.cropSuggestion.map((suggestion) => (
							<span
								className="produce-category-suggestion"
								key={suggestion.category}
							>
								<Badge pill variant={suggestionVariantMap[suggestion.category]}>
									<FormattedMessage
										{...produceCategoryNames[suggestion.category]}
									/>
								</Badge>
							</span>
						))}
					</Col>
				</Row>
				<Row>
					<Col>
						<Form.Label className="titleMarkedPosition float-right">
							<div>
								<FormattedMessage {...commonMessages.requiredStar} />
							</div>
						</Form.Label>
					</Col>
				</Row>
				<Form.Group controlId="formGrifFieldName">
					<Form.Label className="font-weight-bold">
						<FormattedMessage {...messages.selectProduce} />
					</Form.Label>
					<Select
						components={{ Option: CustomOption }}
						onBlur={() => formik.setFieldTouched("produceName", true)}
						onChange={(e) => {
							const option = (e as unknown) as SelectOption;
							formik.setFieldValue("produceName", option.value);
						}}
						options={options}
						placeholder={formatMessage(commonMessages.choose)}
						required
						styles={styles}
					/>
					{formik.errors.produceName && formik.touched.produceName && (
						<div className="input-feedback">{formik.errors.produceName}</div>
					)}
				</Form.Group>
				<Form.Row>
					<Col>
						<Form.Group>
							<Form.Label className="font-weight-bold">
								<FormattedMessage {...messages.selectPlantingDate} /> *
							</Form.Label>
							<DatePicker
								inputClassName="datePicker"
								inputPlaceholder={formatMessage(commonMessages.datePlaceholder)}
								onChange={(plantingDate) => {
									formik.values.plantingDate = String(getDate(plantingDate));
									setSelectedPlantingDate(plantingDate);
								}}
								shouldHighlightWeekends
								value={selectedPlantingDate}
							/>
							{formik.errors.plantingDate && formik.touched.plantingDate && (
								<div className="input-feedback">
									{formik.errors.plantingDate}
								</div>
							)}
						</Form.Group>
					</Col>
					<Col>
						<Form.Label className="font-weight-bold">
							<FormattedMessage {...messages.selectHarvestDate} />
						</Form.Label>
						<DatePicker
							inputClassName="datePicker"
							inputPlaceholder={formatMessage(commonMessages.datePlaceholder)}
							onChange={handleHarvestDate}
							shouldHighlightWeekends
							value={selectedHarvestDate}
						/>
					</Col>
				</Form.Row>
				<Form.Group as={Col}>
					<Button
						className="addButton float-right"
						type="submit"
						variant="primary"
					>
						<FormattedMessage {...messages.plantingHistoryButton} />
					</Button>
				</Form.Group>
			</form>
		</>
	);
});
