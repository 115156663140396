export const formStyles = {
	form: {
		margin: "50px",
	},
	button: {
		margin: "10px",
		width: "175px",
	},
	alert: {
		margin: "10px",
		width: "175px",
		alignItems: "center",
	},
};
