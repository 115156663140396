import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { observer } from "mobx-react";

import { Home } from "./pages/Home";
import { NewProduce } from "./pages/NewProduce";
import { NewLand } from "./pages/NewLand";
import { Lands } from "./pages/Lands";
import { GeneralInformationPage } from "./pages/GeneralInformationPage";
import { AddNewPlantingHistoryPage } from "./pages/AddNewPlantingHistoryPage";
import { NewOperationType } from "./pages/NewOperationType";
import { AddNewOperationPage } from "./pages/AddNewOperationPage";
import { UpdateUserData } from "./pages/UpdateUserData";
import { LoginPage } from "./pages/LoginPage";
import { LoginStoreContext } from "../stores/LoginStore";
import { RegisterPage } from "./pages/RegisterPage";
import { States } from "../stores/State";
import { LoadingPage } from "./pages/LoadingPage";
import { FailedAlert } from "./FailedAlert";

export const Routes: React.FC = observer(() => {
	const state = useContext(LoginStoreContext);
	// if state isn't stored in variable, won't rerender the page when state changes
	const isLoggedinState = state.isLoggedinState;

	const handleAlert = () => {
		state.resetisLoggedinState();
	};

	if (isLoggedinState === States.INITIAL) {
		state.runIntrospect();
	}

	if (isLoggedinState === States.ERROR) {
		return <FailedAlert onHide={handleAlert} />;
	} else if (isLoggedinState === States.PENDING) {
		return <LoadingPage />;
	} else if (isLoggedinState === States.DONE) {
		if (!state.loggedIn) {
			return (
				<Switch>
					<Route component={RegisterPage} path="/register" />
					<Route component={LoginPage} path="/login" />
					<Redirect to="/login" />;
				</Switch>
			);
		} else {
			return (
				<Switch>
					<Route component={Home} exact path="/" />
					<Route component={NewProduce} path="/produce" />
					<Route component={NewLand} path="/newland" />
					<Route component={Lands} path="/lands" />
					<Route component={GeneralInformationPage} path="/general/:id" />
					<Route component={NewOperationType} path="/operationType" />
					<Route
						component={AddNewPlantingHistoryPage}
						path="/plantingHistory/:id"
					/>
					<Route component={AddNewOperationPage} path="/addOperation/:id" />
					<Route component={LoginPage} path="/login" />
					<Route component={UpdateUserData} path="/updateUserData" />
				</Switch>
			);
		}
	} else {
		return null;
	}
});
