import React, { Fragment } from "react";
import { IntlProvider } from "react-intl";
import { observer } from "mobx-react";

import { Locales } from "./Locales";

const hu = require("../resources/locales/hu.json");

const messages: Record<Locales, any> = {
	en: null,
	hu,
};

type Props = {
	children: any;
	locale: Locales;
};

export const Provider: React.FC<Props> = observer(
	({ children, locale = Locales.ENGLISH }) => {
		return (
			<IntlProvider
				locale={locale}
				messages={messages[locale]}
				textComponent={Fragment}
			>
				{children}
			</IntlProvider>
		);
	}
);
