import React from "react";

import { OperationType } from "../../stores/OperationTypeEnum";
import { RegisterForm } from "../RegisterForm";

export const RegisterPage: React.FC = () => {
	return (
		<div>
			<RegisterForm pageType={OperationType.Register} />
		</div>
	);
};
