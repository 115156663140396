import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import * as Yup from "yup";
import { useFormik } from "formik";
import { defineMessages, useIntl, FormattedMessage } from "react-intl";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IconContext } from "react-icons";
import { GiFarmer } from "react-icons/gi";

import { formStyles } from "./styles/Styles";
import { UsersStoreContext } from "../stores/UserStore";
import { commonMessages } from "../i18n/CommonMessages";
import { OperationType } from "../stores/OperationTypeEnum";
import { States } from "../stores/State";
import { FailedAlert } from "./FailedAlert";
import { SuccesfulAlert } from "./SuccesfulAlert";

const messages = defineMessages({
	currentPassword: {
		id: "registerForm.currentPassword",
		defaultMessage: "Current password",
	},
	username: {
		id: "registerForm.username",
		defaultMessage: "Username",
	},
	password: {
		id: "registerForm.password",
		defaultMessage: "Password",
	},
	usernamePlaceholder: {
		id: "registerForm.usernamePlaceholder",
		defaultMessage: "Enter a username",
	},
	passwordPlaceholder: {
		id: "registerForm.passwordPlaceholder",
		defaultMessage: "Enter a  password",
	},
	passwordAgainPlaceholder: {
		id: "registerForm.PasswordAgainPlaceholder",
		defaultMessage: "Enter your password again",
	},
	updateUserDataButton: {
		id: "registerForm.updateUserDataButton",
		defaultMessage: "Update profile",
	},
	passwordCheck: {
		id: "registerForm.passwordCheck",
		defaultMessage:
			"The password must contain 7 Characters with uppercase, lowercase and number",
	},
	firstName: {
		id: "registerForm.FirstName",
		defaultMessage: "Firstname",
	},
	lastName: {
		id: "registerForm.newLastName",
		defaultMessage: "Lastname",
	},
	firstNamePlaceholder: {
		id: "registerForm.newFirstNamePlaceholder",
		defaultMessage: "Enter your firstname",
	},
	lastNamePlaceholder: {
		id: "registerForm.newLastNamePlaceholder",
		defaultMessage: "Enter your  lastname",
	},
	passwordsMatching: {
		id: "registerForm.passwordsMatching",
		defaultMessage: "Passwords do not match",
	},
	nameCheck: {
		id: "registerForm.nameCheck",
		defaultMessage: "Wrong name format",
	},
	registerButton: {
		id: "registerForm.registerbutton",
		defaultMessage: "Register",
	},
	passwordAgain: {
		id: "registerForm.passwordAgain",
		defaultMessage: "Password again",
	},
	alreadyHaveAnAccount: {
		id: "registerForm.alreadyHaveAnAccount",
		defaultMessage: "I already have an account",
	},
});

type Props = {
	pageType?: OperationType;
};

export const RegisterForm: React.FC<Props> = observer((page) => {
	const state = useContext(UsersStoreContext);
	const { formatMessage } = useIntl();

	let pageTypeCount = OperationType.Update;
	if (page.pageType === OperationType.Register) {
		pageTypeCount = OperationType.Register;
	}

	const submitButtonText = {
		[OperationType.Update]: messages.updateUserDataButton,
		[OperationType.Register]: messages.registerButton,
	};

	// if state isn't stored in variable, won't rerender the page when state changes
	const newUserState = state.newUserState;
	const updateUserState = state.updateUserDataState;

	const handleNewUserAlert = () => {
		state.resetNewUserState();
	};

	const handleUpdateUserAlert = () => {
		state.resetUpdateUserDataState();
	};

	const formik = useFormik({
		initialValues: {
			username: "",
			password: "",
			newPassword: "",
			firstName: "",
			lastName: "",
		},
		onSubmit: (values) => {
			const username = values.username;
			const password = values.password;
			const firstname = values.firstName;
			const lastname = values.lastName;
			const newPassword = values.newPassword;
			const userParam = {
				username,
				password,
				newPassword,
				firstname,
				lastname,
			};
			if (page.pageType === OperationType.Update) {
				state.updateUserData(userParam);
			} else if (page.pageType === OperationType.Register) {
				state.addUser(userParam);
			}
		},
		validationSchema: Yup.object().shape({
			newUsername: Yup.string().min(
				5,
				formatMessage(commonMessages.tooShortValidation, {
					entity: formatMessage(messages.username),
					number: 5,
				})
			),
			firstName: Yup.string()
				.min(
					2,
					formatMessage(commonMessages.tooShortValidation, {
						entity: formatMessage(messages.firstName),
						number: 2,
					})
				)
				.matches(
					/^[A-ZÁÉÍÚÜŐŰ][a-záéúíóüőű]+$/,
					formatMessage(messages.nameCheck)
				),
			lastName: Yup.string()
				.min(
					2,
					formatMessage(commonMessages.tooShortValidation, {
						entity: formatMessage(messages.lastName),
						number: 2,
					})
				)
				.matches(
					/^[A-ZÁÉÍÚÜŐŰ][a-záéúíóüőű]+$/,
					formatMessage(messages.nameCheck)
				),
			password: Yup.string()
				.min(
					7,
					formatMessage(commonMessages.tooShortValidation, {
						entity: formatMessage(messages.currentPassword),
						number: 7,
					})
				)
				.matches(
					/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{7,}$/,
					formatMessage(messages.passwordCheck)
				)
				.required(formatMessage(commonMessages.required)),

			newPassword: Yup.string()
				.min(
					7,
					formatMessage(commonMessages.tooShortValidation, {
						entity: formatMessage(messages.currentPassword),
						number: 7,
					})
				)
				.matches(
					/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{7,}$/,
					formatMessage(messages.passwordCheck)
				),
		}),
	});

	const resetForm = formik.resetForm;
	const dirty = formik.dirty;
	useEffect(() => {
		if (
			(updateUserState === States.DONE || newUserState === States.DONE) &&
			dirty
		) {
			resetForm();
		}
	}, [updateUserState, newUserState, resetForm, dirty]);

	return (
		<>
			<Container>
				<Row className="justify-content-md-center mt-5">
					<Col lg="6" xs>
						{newUserState === States.DONE && (
							<SuccesfulAlert onHide={handleNewUserAlert} />
						)}
						{newUserState === States.ERROR && (
							<FailedAlert onHide={handleNewUserAlert} />
						)}
						{updateUserState === States.DONE && (
							<SuccesfulAlert onHide={handleUpdateUserAlert} />
						)}
						{updateUserState === States.ERROR && (
							<FailedAlert onHide={handleUpdateUserAlert} />
						)}
					</Col>
				</Row>
			</Container>

			<form action="" onSubmit={formik.handleSubmit} style={formStyles.form}>
				<Row>
					<Col className="d-none d-lg-block" lg={6} sm={12}>
						<IconContext.Provider
							value={{
								color: "primary",
								className: "global-class-name",
								size: "500px",
							}}
						>
							<GiFarmer />
						</IconContext.Provider>
					</Col>

					<Col lg={6} sm={12}>
						{page.pageType === OperationType.Register && (
							<Form.Group>
								<Link to="/login">
									<Button className="navigationButton" variant="primary">
										<FormattedMessage {...messages.alreadyHaveAnAccount} />
									</Button>
								</Link>
							</Form.Group>
						)}

						<Form.Group controlId="formGridNewUsername">
							<Form.Label className="font-weight-bold">
								<FormattedMessage {...messages.username} />
							</Form.Label>
							<Form.Control
								name="username"
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								placeholder={formatMessage(messages.usernamePlaceholder)}
								type="text"
								value={formik.values.username}
							/>
							{formik.errors.username && formik.touched.username && (
								<div className="input-feedback">{formik.errors.username}</div>
							)}
						</Form.Group>
						<Form.Group controlId="formGridNewFirstName">
							<Form.Label className="font-weight-bold">
								<FormattedMessage {...messages.firstName} />
							</Form.Label>
							<Form.Control
								name="firstName"
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								placeholder={formatMessage(messages.firstNamePlaceholder)}
								type="text"
								value={formik.values.firstName}
							/>
							{formik.errors.firstName && formik.touched.firstName && (
								<div className="input-feedback">{formik.errors.firstName}</div>
							)}
						</Form.Group>
						<Form.Group controlId="formGridNewLastName">
							<Form.Label className="font-weight-bold">
								<FormattedMessage {...messages.lastName} />
							</Form.Label>
							<Form.Control
								name="lastName"
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								placeholder={formatMessage(messages.lastNamePlaceholder)}
								type="text"
								value={formik.values.lastName}
							/>
							{formik.errors.lastName && formik.touched.lastName && (
								<div className="input-feedback">{formik.errors.lastName}</div>
							)}
						</Form.Group>
						<Form.Group controlId="formGridNewPassword">
							<Form.Label className="font-weight-bold">
								<FormattedMessage {...messages.password} />
							</Form.Label>
							<Form.Control
								name="newPassword"
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								placeholder={formatMessage(messages.passwordPlaceholder)}
								type="password"
								value={formik.values.newPassword}
							/>
							<i aria-hidden="true" className="fa fa-eye" />

							{formik.errors.newPassword && formik.touched.newPassword && (
								<div className="input-feedback">
									{formik.errors.newPassword}
								</div>
							)}
						</Form.Group>
						<Form.Group controlId="formGridPassword">
							<Form.Label className="font-weight-bold">
								<FormattedMessage {...messages.passwordAgain} />
							</Form.Label>
							<Form.Control
								name="password"
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								placeholder={formatMessage(messages.passwordAgainPlaceholder)}
								type="password"
								value={formik.values.password}
							/>
							{formik.errors.password && formik.touched.password && (
								<div className="input-feedback">{formik.errors.password}</div>
							)}
						</Form.Group>
						<Form.Group>
							<Button
								className="float-right"
								disabled={
									formik.values.firstName === "" ||
									formik.values.username === "" ||
									formik.values.password === "" ||
									formik.values.newPassword === "" ||
									formik.values.lastName === ""
								}
								style={formStyles.form}
								type="submit"
								variant="primary"
							>
								<FormattedMessage {...submitButtonText[pageTypeCount]} />
							</Button>
						</Form.Group>
					</Col>
				</Row>
			</form>
		</>
	);
});
