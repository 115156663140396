import React from "react";
import { Pagination } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import { PagingType } from "../types/PagingType";

type Props = {
	pagination: PagingType;
	/**
	 * The base url of the current page.
	 *
	 * E.g. /lands?
	 */
	url: string;
	size?: "sm" | "lg";
};

export const PaginationComponent: React.FC<Props> = ({
	pagination,
	url,
	size,
}) => {
	const history = useHistory();

	var searchParams = new URLSearchParams();

	return (
		<Pagination size={size}>
			<Pagination.First
				disabled={pagination.currentPage === 1}
				onClick={() => {
					searchParams.set("page", "1");
					history.push(url + searchParams.toString());
				}}
			/>
			<Pagination.Prev
				disabled={pagination.currentPage === 1}
				onClick={() => {
					searchParams.set("page", `${pagination.currentPage - 1}`);
					history.push(url + searchParams.toString());
				}}
			/>
			{pagination.currentPage !== 1 && (
				<Pagination.Item
					onClick={() => {
						searchParams.set("page", `${pagination.currentPage - 1}`);
						history.push(url + searchParams.toString());
					}}
				>
					{pagination.currentPage - 1}
				</Pagination.Item>
			)}

			<Pagination.Item active>{pagination.currentPage}</Pagination.Item>

			{pagination.currentPage !== pagination.endPage && (
				<Pagination.Item
					onClick={() => {
						searchParams.set("page", `${pagination.currentPage + 1}`);
						history.push(url + searchParams.toString());
					}}
				>
					{pagination.currentPage + 1}
				</Pagination.Item>
			)}

			<Pagination.Next
				disabled={pagination.currentPage === pagination.endPage}
				onClick={() => {
					searchParams.set("page", `${pagination.currentPage + 1}`);
					history.push(url + searchParams.toString());
				}}
			/>

			<Pagination.Last
				disabled={pagination.currentPage === pagination.endPage}
				onClick={() => {
					searchParams.set("page", `${pagination.endPage}`);
					history.push(url + searchParams.toString());
				}}
			/>
		</Pagination>
	);
};
