import { createContext } from "react";
import { observable, action, runInAction } from "mobx";

import { Land, LandDto, StatisticsDto } from "../types/Land";
import { landApi } from "../api/LandApi";
import { States } from "./State";
import { ProduceCategory } from "../types/Produce";
import { PagingType } from "../types/PagingType";
import { setPagination } from "../utils/PaginationUtils";

class LandStore {
	@observable
	lands: Land[] = [];

	@observable
	land?: Land;

	@observable
	pagination: PagingType = {
		totalRows: 0,
		pageSize: 10,
		currentPage: 1,
		endPage: 1,
		entitiesOnLastPage: 0,
	};

	@observable
	paginationState: States = States.INITIAL;

	@observable
	landState: States = States.INITIAL;

	@observable
	landsState: States = States.INITIAL;

	@observable
	deleteLandState: States = States.INITIAL;

	@observable
	newLandState: States = States.INITIAL;

	@observable
	updateLandState: States = States.INITIAL;

	@observable
	countProduceState: States = States.INITIAL;

	@observable
	stat?: StatisticsDto;

	@observable
	produceCountByCategory: { [key in ProduceCategory]: number } = {
		[ProduceCategory.RowCrop]: 0,
		[ProduceCategory.Legumes]: 0,
		[ProduceCategory.GrassesAndCereals]: 0,
		[ProduceCategory.GreenManure]: 0,
	};

	@action.bound
	resetNewLandState(): void {
		this.newLandState = States.INITIAL;
	}

	@action.bound
	resetUpdateLandState(): void {
		this.updateLandState = States.INITIAL;
	}

	@action.bound
	resetDeleteLandState(): void {
		this.deleteLandState = States.INITIAL;
	}

	@action.bound
	resetLandState(): void {
		this.landState = States.INITIAL;
	}

	@action.bound
	resetLandsState(): void {
		this.landsState = States.INITIAL;
	}

	@action.bound
	resetCountProduceState(): void {
		this.countProduceState = States.INITIAL;
	}
	setCurrentPage = (currentPage: number) => {
		this.pagination.currentPage = currentPage;
	};

	@action.bound
	addNewLand(land: LandDto) {
		this.newLandState = States.PENDING;
		landApi
			.postLand(land)
			.then(() => {
				runInAction(() => {
					this.newLandState = States.DONE;
				});
			})
			.catch((error) => {
				runInAction(() => {
					this.newLandState = States.ERROR;
					console.log(error);
				});
			});
	}

	@action.bound
	getLandById(landId: number) {
		this.landState = States.PENDING;
		landApi
			.getLandById(landId)
			.then((land) => {
				runInAction(() => {
					this.land = land;
					this.landState = States.DONE;
				});
			})
			.catch((error) => {
				runInAction(() => {
					this.landState = States.ERROR;
					console.log(error);
				});
			});
	}

	@action.bound
	getLandsPaginated() {
		landApi
			.getLands(this.pagination.currentPage, this.pagination.pageSize)
			.then((lands) => {
				runInAction(() => {
					this.lands = lands;
					this.landsState = States.DONE;
				});
			})
			.catch((error) => {
				runInAction(() => {
					this.landsState = States.ERROR;
					console.log(error);
				});
			});
	}

	@action.bound
	getLands() {
		this.lands = [];
		this.landsState = States.PENDING;
		this.paginationState = States.PENDING;
		landApi
			.getTotalLandsNumber()
			.then((number) => {
				runInAction(() => {
					this.pagination = setPagination(
						number,
						this.pagination.pageSize,
						this.pagination.currentPage
					);
					this.paginationState = States.DONE;
					this.landState = States.PENDING;
					this.getLandsPaginated();
				});
			})
			.catch((error) => {
				console.log(error);
				this.paginationState = States.ERROR;
				this.landState = States.ERROR;
			});
	}

	@action.bound
	getAllLands() {
		this.lands = [];
		this.landsState = States.PENDING;
		landApi
			.getAllLands()
			.then((lands) => {
				runInAction(() => {
					this.lands = lands;
					this.landsState = States.DONE;
				});
			})
			.catch((error) => {
				runInAction(() => {
					this.landsState = States.ERROR;
					console.log(error);
				});
			});
	}

	@action.bound
	deleteLands(landId: number) {
		this.deleteLandState = States.PENDING;
		landApi
			.deleteLand(landId)
			.then(() => {
				runInAction(() => {
					this.deleteLandState = States.DONE;
					if (
						this.pagination.entitiesOnLastPage === 1 &&
						this.pagination.currentPage === this.pagination.endPage
					) {
						this.pagination.currentPage = this.pagination.currentPage - 1;
					}
					this.getLands();
				});
			})
			.catch((error) => {
				runInAction(() => {
					this.deleteLandState = States.ERROR;
					console.log(error);
				});
			});
	}

	@action.bound
	updateLand(land: Partial<LandDto>, landId: number) {
		this.updateLandState = States.PENDING;
		landApi
			.updateLand(land, landId)
			.then(() => {
				runInAction(() => {
					this.updateLandState = States.DONE;
					this.getLands();
				});
			})
			.catch((error) => {
				runInAction(() => {
					this.updateLandState = States.ERROR;
					console.log(error);
				});
			});
	}
	@action.bound
	countProduceByType(landId: number) {
		this.countProduceState = States.PENDING;
		landApi
			.getProduceTypeCount(landId)
			.then((stat) => {
				runInAction(() => {
					this.stat = stat;
					this.produceCountByCategory[ProduceCategory.RowCrop] = stat.rowCrops;
					this.produceCountByCategory[ProduceCategory.Legumes] = stat.legumes;
					this.produceCountByCategory[ProduceCategory.GrassesAndCereals] =
						stat.grasses;
					this.produceCountByCategory[ProduceCategory.GreenManure] =
						stat.manure;
				});
			})
			.catch((error) => {
				runInAction(() => {
					this.countProduceState = States.ERROR;
					console.log(error);
				});
			});
	}
}

export const LandStoreContext = createContext(new LandStore());
