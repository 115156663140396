import React, { useContext } from "react";
import { Redirect } from "react-router-dom";

import { LoginForm } from "../LoginForm";
import { LoginStoreContext } from "../../stores/LoginStore";

export const LoginPage: React.FC = () => {
	const state = useContext(LoginStoreContext);

	if (state.loggedIn) {
		return <Redirect to="/" />;
	}
	return (
		<div>
			<LoginForm />
		</div>
	);
};
