import React, { useContext } from "react";
import { observer } from "mobx-react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import * as Yup from "yup";
import { Formik } from "formik";
import { defineMessages, useIntl, FormattedMessage } from "react-intl";
import { Container, Row, Col } from "react-bootstrap";
import "./styles/NavElement.css";
import { Link } from "react-router-dom";
import { IconContext } from "react-icons";
import { GiFarmer } from "react-icons/gi";

import { formStyles } from "./styles/Styles";
import { LoginStoreContext } from "../stores/LoginStore";
import { commonMessages } from "../i18n/CommonMessages";
import { LoginDto } from "../types/User";
import { States } from "../stores/State";
import { FailedAlert } from "./FailedAlert";

const messages = defineMessages({
	username: {
		id: "logInForm.username",
		defaultMessage: "Username",
	},
	password: {
		id: "logInForm.password",
		defaultMessage: "Password",
	},
	usernamePlaceholder: {
		id: "logInForm.usernamePlaceholder",
		defaultMessage: "Enter your username",
	},
	passwordPlaceholder: {
		id: "logInForm.passwordPlaceholder",
		defaultMessage: "Enter your password",
	},
	notRegisteredYet: {
		id: "logInForm.notRegisteredYet",
		defaultMessage: "Not registered yet?",
	},
});

export const LoginForm = observer(() => {
	const state = useContext(LoginStoreContext);
	// if state isn't stored in variable, won't rerender the page when state changes
	const logInState = state.logInState;

	const { formatMessage } = useIntl();

	const handleAlert = () => {
		state.resetLoginState();
	};

	return (
		<Formik
			initialValues={{
				username: "",
				password: "",
			}}
			onSubmit={(values) => {
				const dto: LoginDto = {
					username: values.username,
					password: values.password,
				};
				state.logIn(dto);
			}}
			validationSchema={Yup.object().shape({
				username: Yup.string()
					.min(
						5,
						formatMessage(commonMessages.tooShortValidation, {
							entity: formatMessage(messages.username),
							number: 5,
						})
					)
					.required(formatMessage(commonMessages.required)),
				password: Yup.string()
					.min(
						7,
						formatMessage(commonMessages.tooShortValidation, {
							entity: formatMessage(messages.password),
							number: 7,
						})
					)
					.required(formatMessage(commonMessages.required)),
			})}
		>
			{(props) => {
				const {
					values,
					touched,
					errors,
					handleChange,
					handleSubmit,
					handleBlur,
				} = props;

				return (
					<>
						<Container>
							<Row className="justify-content-md-center mt-5">
								<Col lg="6" xs>
									{logInState === States.ERROR && (
										<FailedAlert onHide={handleAlert} />
									)}
								</Col>
							</Row>
						</Container>
						<form action="" onSubmit={handleSubmit} style={formStyles.form}>
							<Row>
								<Col className="d-none d-lg-block" lg={6} sm={12}>
									<IconContext.Provider
										value={{
											color: "primary",
											className: "global-class-name",
											size: "500px",
										}}
									>
										<GiFarmer />
									</IconContext.Provider>
								</Col>

								<Col lg={6} sm={12}>
									<Form.Group>
										<Link to="/register">
											<Button className="navigationButton" variant="primary">
												<FormattedMessage {...messages.notRegisteredYet} />
											</Button>
										</Link>
									</Form.Group>

									<Form.Group controlId="formGridUsername">
										<Form.Label className="font-weight-bold">
											<FormattedMessage {...messages.username} />
										</Form.Label>
										<Form.Control
											name="username"
											onBlur={handleBlur}
											onChange={handleChange}
											placeholder={formatMessage(messages.usernamePlaceholder)}
											type="text"
											value={values.username}
										/>
										{errors.username && touched.username && (
											<div className="input-feedback">{errors.username}</div>
										)}
									</Form.Group>
									<Form.Group controlId="formGridPassword">
										<Form.Label className="font-weight-bold">
											<FormattedMessage {...messages.password} />
										</Form.Label>
										<Form.Control
											name="password"
											onBlur={handleBlur}
											onChange={handleChange}
											placeholder={formatMessage(messages.passwordPlaceholder)}
											type="password"
											value={values.password}
										/>
										{errors.password && touched.password && (
											<div className="input-feedback">{errors.password}</div>
										)}
									</Form.Group>
									<Form.Group>
										<Button
											className="float-right"
											disabled={
												values.username === "" || values.password === ""
											}
											style={formStyles.form}
											type="submit"
											variant="primary"
										>
											<FormattedMessage {...commonMessages.login} />
										</Button>
									</Form.Group>
								</Col>
							</Row>
						</form>
					</>
				);
			}}
		</Formik>
	);
});
