import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import Alert from "react-bootstrap/Alert";
import { FormattedMessage } from "react-intl";
import { FaTimesCircle } from "react-icons/fa";

import { formStyles } from "./styles/Styles";
import { commonMessages } from "../i18n/CommonMessages";

type Props = { onHide(): void };

export const FailedAlert: React.FC<Props> = observer(({ onHide }) => {
	const [showAlert, setShowAlert] = useState(false);

	useEffect(() => {
		setShowAlert(true);
		const timerId = setTimeout(() => {
			setShowAlert(false);
			onHide();
		}, 3000);
		return () => {
			clearTimeout(timerId);
		};
	}, [onHide]);

	return (
		<Alert show={showAlert} variant="danger">
			<FaTimesCircle />
			<b style={formStyles.alert}>
				<FormattedMessage {...commonMessages.failed} />
			</b>
		</Alert>
	);
});
