import { DayValue } from "react-modern-calendar-datepicker";

export const getDate = (selectedPlantingDate: DayValue) => {
	let date = null;
	if (selectedPlantingDate) {
		date = new Date(
			selectedPlantingDate.year,
			// The origin of this strange behavior comes from "react-modern-calendar-datepicker"-s DayValue
			// but the reason is unknhown yet
			selectedPlantingDate.month - 1,
			selectedPlantingDate.day + 1
		);
	}
	return date?.toISOString();
};
