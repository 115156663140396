import { createContext } from "react";
import { observable, action, runInAction } from "mobx";

import { Produce, ProduceDto } from "../types/Produce";
import { produceApi } from "../api/ProduceApi";
import { States } from "./State";

class ProduceStore {
	@observable
	produces: Produce[] = [];

	@observable
	producesState: States = States.INITIAL;

	@observable
	newProduceState: States = States.INITIAL;

	@action.bound
	resetNewProduceState(): void {
		this.newProduceState = States.INITIAL;
	}

	@action.bound
	resetProducesState(): void {
		this.producesState = States.INITIAL;
	}

	@action.bound
	addNewProduce(produce: ProduceDto) {
		this.newProduceState = States.PENDING;
		produceApi
			.postProduce(produce)
			.then(() => {
				runInAction(() => {
					this.newProduceState = States.DONE;
				});
			})
			.catch((error) => {
				runInAction(() => {
					this.newProduceState = States.ERROR;
					console.log(error);
				});
			});
	}

	@action.bound
	getProduces() {
		this.produces = [];
		this.producesState = States.PENDING;
		produceApi
			.getProduce()
			.then((produces) => {
				runInAction(() => {
					this.produces = produces;
					this.producesState = States.DONE;
				});
			})
			.catch((error) => {
				runInAction(() => {
					this.producesState = States.ERROR;
					console.log(error);
				});
			});
	}
}

export const ProduceStoreContext = createContext(new ProduceStore());
