import React, { useContext, useEffect } from "react";
import Row from "react-bootstrap/Row";
import { observer } from "mobx-react";
import { Container, Col } from "react-bootstrap";

import { Land } from "../types/Land";
import { LandCard } from "./LandCard";
import { LandStoreContext } from "./../stores/LandStore";
import { States } from "../stores/State";
import { FailedAlert } from "./FailedAlert";
import { SuccesfulAlert } from "./SuccesfulAlert";

type Props = {
	lands: Land[];
};

export const MapLands: React.FC<Props> = observer(() => {
	const state = useContext(LandStoreContext);
	// if state isn't stored in variable, won't rerender the page when state changes
	const updateLandState = state.updateLandState;
	const deleteLandState = state.deleteLandState;
	const getLandsState = state.landsState;

	useEffect(() => {
		state.getLands();
	}, [state]);

	const handleUpdateAlert = () => {
		state.resetUpdateLandState();
	};
	const handleDeleteAlert = () => {
		state.resetDeleteLandState();
	};
	const handleGetAlert = () => {
		state.resetLandsState();
	};

	return (
		<>
			<Container>
				<Row className="justify-content-md-center mt-5">
					<Col lg="6" xs>
						{updateLandState === States.DONE && (
							<SuccesfulAlert onHide={handleUpdateAlert} />
						)}
						{updateLandState === States.ERROR && (
							<FailedAlert onHide={handleUpdateAlert} />
						)}
						{deleteLandState === States.DONE && (
							<SuccesfulAlert onHide={handleDeleteAlert} />
						)}
						{deleteLandState === States.ERROR && (
							<FailedAlert onHide={handleDeleteAlert} />
						)}
						{getLandsState === States.ERROR && (
							<FailedAlert onHide={handleGetAlert} />
						)}
					</Col>
				</Row>
			</Container>

			<Row lg={5} md={3} xs={2}>
				{state.lands.map((land: Land) => (
					<div key={land.id}>
						<LandCard land={land} />
					</div>
				))}
			</Row>
		</>
	);
});
