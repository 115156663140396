import React, { useState } from "react";
import { observer } from "mobx-react";
import { useHistory, useParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import "./styles/AddNewPlantingHistory.css";
import {
	useIntl,
	defineMessages,
	FormattedMessage,
	FormattedDate,
} from "react-intl";
import {
	Button,
	Modal,
	Row,
	Col,
	ButtonGroup,
	Badge,
	Container,
} from "react-bootstrap";
import DatePicker, { DayValue } from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { AiFillEdit } from "react-icons/ai";

import { getDate } from "../utils/DateFormatter";
import { PlantingHistoryStoreContext } from "../stores/PlantingHistoryStore";
import { commonMessages } from "../i18n/CommonMessages";
import { convertYield, displayYieldForm } from "../utils/UnitFormatters";
import { ProduceCategory } from "../types/Produce";
import { suggestionVariantMap } from "../stores/SuggestionVariantMap";
import { States } from "../stores/State";
import { FailedAlert } from "./FailedAlert";
import { setPage } from "../utils/PaginationUtils";
import { useQuery } from "../utils/NavigationHooks";
import { PaginationContainer } from "./PaginationContainer";

const messages = defineMessages({
	produceTable: {
		id: "plantingHistory.produceTable",
		defaultMessage: "Produce name",
	},
	landTable: {
		id: "plantingHistory.landTable",
		defaultMessage: "Land name",
	},
	plantingDateTable: {
		id: "plantingHistory.plantingDateTable",
		defaultMessage: "Planting Date",
	},
	harvestDateTable: {
		id: "plantingHistory.harvestDateTable",
		defaultMessage: "Harvest Date",
	},
	addHarvestDateParametered: {
		id: "plantingHistory.addHarvestDateParametered",
		defaultMessage: "Add {name} harvest date",
	},
	addHarvestYield: {
		id: "plantingHistory.addHarvestYield",
		defaultMessage: "Yield",
	},
	harvestYieldUnit: {
		id: "harvestYieldUnit",
		defaultMessage: "Unit",
	},
	produceCategoryName: {
		id: "plantingHistory.produceCategoryName",
		defaultMessage: "Produce category",
	},
});

export const PlantingHistoryList: React.FC = observer(() => {
	const { id } = useParams();
	const produceCategoryNames = {
		[ProduceCategory.RowCrop]: commonMessages.rowCrops,
		[ProduceCategory.Legumes]: commonMessages.legumesName,
		[ProduceCategory.GrassesAndCereals]: commonMessages.grassesAndCerealsName,
		[ProduceCategory.GreenManure]: commonMessages.greenManureName,
	};
	const state = useContext(PlantingHistoryStoreContext);
	// if state isn't stored in variable, won't rerender the page when state changes
	const plantingHistoryState = state.plantingHistoryState;

	const history = useHistory();
	const page = useQuery().get("page");
	const url = `/plantingHistory/${id}?`;

	useEffect(() => {
		setPage(state.setCurrentPage, page, history, state.pagination.endPage, url);
		state.getPlantingHistoryByLand(id);
	}, [history, id, page, state, url]);

	const { formatMessage } = useIntl();

	const [showModal, setShowModal] = useState(false);

	const handleClose = () => {
		setShowModal(false);
	};

	const handleAlert = () => {
		state.resetPlantingHistoryState();
	};

	const [produceYield, setYield] = useState("t");

	const [selectedHarvestDate, setSelectedHarvestDate] = useState<
		DayValue | undefined
	>();

	const handleHarvestDate = (selectedHarvestDate: DayValue) =>
		setSelectedHarvestDate(selectedHarvestDate);

	const [selectedYield, setSelectedYield] = useState<number | undefined>();

	const handleYield = (selectedYield: number) =>
		setSelectedYield(selectedYield);

	const [selectedTableElement, setSelectedTableElement] = useState({
		landId: 0,
		produceId: 0,
		landName: "",
		produceName: "",
		plantingDate: "",
		plantingHistoryId: 0,
		yield: 0,
	});

	const [tableOptions, setTableOptions] = useState({
		buttonMessage: "",
		table: 0,
	});

	const clickAddHarvestDate = () => {
		let updateValue = {
			landId: selectedTableElement.landId,
			produceId: selectedTableElement.produceId,
			plantingDate: selectedTableElement.plantingDate,
			harvestDate: getDate(selectedHarvestDate),
			yield: convertYield(produceYield, selectedYield),
		};
		state.updatePlantingHistory(
			updateValue,
			selectedTableElement.plantingHistoryId,
			selectedTableElement.landId
		);
		setShowModal(false);
	};

	const clickSave = () => {
		let updateValue = {
			landId: selectedTableElement.landId,
			produceId: selectedTableElement.produceId,
			plantingDate: selectedTableElement.plantingDate,
			harvestDate: getDate(selectedHarvestDate),
			yield: convertYield(produceYield, selectedYield),
		};
		state.updatePlantingHistory(
			updateValue,
			selectedTableElement.plantingHistoryId,
			selectedTableElement.landId
		);
		setShowModal(false);
	};

	const data = {
		columns: [
			{
				label: formatMessage(messages.landTable),
				field: "landName",
				sort: "asc",
				width: 200,
			},
			{
				label: formatMessage(messages.produceTable),
				field: "produceName",
				sort: "asc",
				width: 200,
			},
			{
				label: formatMessage(messages.produceCategoryName),
				field: "produceCategory",
				sort: "asc",
				width: 180,
			},
			{
				label: formatMessage(messages.plantingDateTable),
				field: "plantingDate",
				sort: "asc",
				width: 180,
			},
			{
				label: formatMessage(messages.harvestDateTable),
				field: "harvestDate",
				sort: "asc",
				width: 180,
			},
			{
				label: formatMessage(messages.addHarvestYield),
				field: "Yield",
				sort: "asc",
				width: 180,
			},
		],
		rows: state.plantingHistory.map((plantingHistory) => {
			return {
				landName: plantingHistory.land.name,
				produceName: plantingHistory.produce.name,
				category: (
					<Badge
						pill
						variant={suggestionVariantMap[plantingHistory.produce.category]}
					>
						<FormattedMessage
							{...produceCategoryNames[plantingHistory.produce.category]}
						/>{" "}
					</Badge>
				),

				plantingDate: <FormattedDate value={plantingHistory.plantingDate} />,
				harvestDate: plantingHistory.harvestDate ? (
					<FormattedDate value={plantingHistory.harvestDate} />
				) : (
					<Button
						onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
							event.preventDefault();
							setShowModal(true);
							setSelectedTableElement({
								landId: plantingHistory.land.id,
								produceId: plantingHistory.produce.id,
								landName: plantingHistory.land.name,
								produceName: plantingHistory.produce.name,
								plantingDate: String(plantingHistory.plantingDate),
								plantingHistoryId: plantingHistory.id,
								yield: 0,
							});
							setTableOptions({
								buttonMessage: formatMessage(commonMessages.finish),
								table: 0,
							});
						}}
						size="sm"
						variant="outline-primary"
					>
						<FormattedMessage {...commonMessages.finish} />
					</Button>
				),
				yield: displayYieldForm(plantingHistory.yield),
				edit: (
					<Button
						onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
							event.preventDefault();
							setShowModal(true);
							setSelectedTableElement({
								landId: plantingHistory.land.id,
								produceId: plantingHistory.produce.id,
								landName: plantingHistory.land.name,
								produceName: plantingHistory.produce.name,
								plantingDate: String(plantingHistory.plantingDate),
								plantingHistoryId: plantingHistory.id,
								yield:
									produceYield === "kg"
										? plantingHistory.yield
										: plantingHistory.yield / 1000,
							});
							setTableOptions({
								buttonMessage: formatMessage(commonMessages.save),
								table: 0,
							});
						}}
						size="sm"
						variant="outline-primary"
					>
						<AiFillEdit />
					</Button>
				),
			};
		}),
	};

	const buttonAction = () => {
		tableOptions.table === 0 ? clickSave() : clickAddHarvestDate();
	};

	return (
		<div>
			<Container>
				<Row className="justify-content-md-center mt-5">
					<Col lg="6" xs>
						{plantingHistoryState === States.ERROR && (
							<FailedAlert onHide={handleAlert} />
						)}
					</Col>
				</Row>
			</Container>
			<MDBTable btn className="tableMargin" fixed striped>
				<MDBTableHead columns={data.columns} />
				<MDBTableBody rows={data.rows} />
			</MDBTable>

			<PaginationContainer pagination={state.pagination} url={url} />

			<Modal onHide={handleClose} show={showModal}>
				<Modal.Header closeButton>
					<Modal.Title>
						<FormattedMessage
							{...messages.addHarvestDateParametered}
							values={{
								name: selectedTableElement.produceName,
							}}
						/>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col>
							<h5>
								<FormattedMessage {...messages.plantingDateTable} />:
							</h5>
						</Col>
						<Col>
							<h5>
								<FormattedDate value={selectedTableElement.plantingDate} />
							</h5>
						</Col>
					</Row>
					<Row>
						<Col>
							<h5 className="modalHarvestDate">
								<FormattedMessage {...messages.harvestDateTable} />:
							</h5>
						</Col>
						<Col>
							<DatePicker
								inputClassName="datePicker"
								inputPlaceholder={formatMessage(commonMessages.datePlaceholder)}
								onChange={handleHarvestDate}
								shouldHighlightWeekends
								value={selectedHarvestDate}
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<h5>
								<FormattedMessage {...messages.addHarvestYield} />:
							</h5>
						</Col>
						<Col>
							<h5>
								<FormattedMessage {...messages.harvestYieldUnit} />:
							</h5>
						</Col>
					</Row>

					<Row>
						<Col>
							<input
								defaultValue={selectedTableElement.yield}
								min={0}
								onChange={(event) => handleYield(Number(event.target.value))}
								type="number"
								value={selectedYield}
							/>
						</Col>
						<Col>
							<ButtonGroup size="sm">
								<Button
									onClick={(
										event: React.MouseEvent<HTMLDivElement, MouseEvent>
									) => {
										event.preventDefault();
										setYield("t");
									}}
									value="t"
									variant={produceYield === "t" ? "primary" : "secondary"}
								>
									t
								</Button>
								<Button
									onClick={(
										event: React.MouseEvent<HTMLDivElement, MouseEvent>
									) => {
										event.preventDefault();
										setYield("kg");
									}}
									value="kg"
									variant={produceYield === "kg" ? "primary" : "secondary"}
								>
									kg
								</Button>
							</ButtonGroup>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={handleClose} variant="secondary">
						<FormattedMessage {...commonMessages.closeButton} />
					</Button>
					<Button
						disabled={!selectedHarvestDate}
						onClick={buttonAction}
						variant="primary"
					>
						{tableOptions.buttonMessage}
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
});
