import React, { useEffect, useContext, useState } from "react";
import { observer } from "mobx-react";
import { useFormik } from "formik";
import Form from "react-bootstrap/Form";
import { useParams } from "react-router-dom";
import { Col, Button, Container, Row } from "react-bootstrap";
import DatePicker, { DayValue } from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import * as Yup from "yup";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import { OperationTypeStoreContext } from "../stores/OperationTypeStore";
import { OperationStoreContext } from "../stores/OperationStore";
import "./styles/AddNewOperation.css";
import { States } from "../stores/State";
import { getDate } from "../utils/DateFormatter";
import { commonMessages } from "../i18n/CommonMessages";
import { FailedAlert } from "./FailedAlert";
import { SuccesfulAlert } from "./SuccesfulAlert";

const messages = defineMessages({
	addOperationTitle: {
		id: "operationForm.addOperationTitle",
		defaultMessage: "Add operation to your land",
	},
	selectOperation: {
		id: "operationForm.selectOperation",
		defaultMessage: "Select your operation",
	},
	addOperationButton: {
		id: "operationForm.addOperationButton",
		defaultMessage: "Add operation",
	},
	descriptionPlaceholder: {
		id: "operationForm.descriptionPlaceholder",
		defaultMessage: "What is this operation about?",
	},
});

export const OperationForm: React.FC = observer(() => {
	const operationTypesState = useContext(OperationTypeStoreContext);
	const operationState = useContext(OperationStoreContext);
	// if state isn't stored in variable, won't rerender the page when state changes
	const newOperationState = operationState.newOperationState;
	const getOperationTypesState = operationTypesState.operationTypesState;

	const [selectedOperationDate, setSelectedOperationDate] = useState<
		DayValue | undefined
	>();

	useEffect(() => {
		operationTypesState.getOperationTypes();
	}, [operationTypesState]);

	let { id } = useParams();

	const { formatMessage } = useIntl();

	const handleAlert = () => {
		operationState.resetNewOperationState();
	};
	const handleGetOperationTypesAlert = () => {
		operationTypesState.resetOperationTypesState();
	};

	const formik = useFormik({
		initialValues: {
			operationType: "",
			description: "",
			date: "",
			transactions: 0,
		},
		onSubmit: (values) => {
			let operation = {
				landId: parseInt(id),
				operationTypeId: parseInt(values.operationType),
				description: values.description,
				date: getDate(selectedOperationDate),
				transactions: values.transactions,
			};
			operationState.addNewOperation(operation);
		},
		validationSchema: Yup.object().shape({
			operationType: Yup.string().required(
				formatMessage(commonMessages.required)
			),
			date: Yup.string().required(formatMessage(commonMessages.required)),
		}),
	});

	const resetForm = formik.resetForm;
	const dirty = formik.dirty;
	useEffect(() => {
		if (newOperationState === States.DONE && dirty) {
			resetForm();
		}
	}, [newOperationState, resetForm, dirty]);

	return (
		<>
			<Container>
				<Row className="justify-content-md-center mt-5">
					<Col lg="6" xs>
						{newOperationState === States.DONE && (
							<SuccesfulAlert onHide={handleAlert} />
						)}
						{newOperationState === States.ERROR && (
							<FailedAlert onHide={handleAlert} />
						)}
						{getOperationTypesState === States.ERROR && (
							<FailedAlert onHide={handleGetOperationTypesAlert} />
						)}
					</Col>
				</Row>
			</Container>
			<form
				action=""
				className="addOperationForm"
				onSubmit={formik.handleSubmit}
			>
				<Form.Group controlId="formGrifFieldName">
					<h1>
						<FormattedMessage {...messages.addOperationTitle} />
					</h1>
					<div>
						<FormattedMessage {...commonMessages.requiredStar} />
					</div>
				</Form.Group>
				<Form.Group controlId="formGrifFieldName">
					<Form.Label className="font-weight-bold">
						<FormattedMessage {...messages.selectOperation} />
					</Form.Label>
					<Form.Control
						as="select"
						name="operationType"
						onBlur={formik.handleBlur}
						onChange={formik.handleChange}
						value={formik.values.operationType}
					>
						<option disabled value="">
							{formatMessage(commonMessages.choose)}
						</option>
						{operationTypesState.operationTypesState === States.DONE
							? operationTypesState.operationTypes.map((operationType) => (
									<option key={operationType.id} value={operationType.id}>
										{operationType.name}
									</option>
							  ))
							: null}
					</Form.Control>
					{formik.errors.operationType && formik.touched.operationType && (
						<div className="input-feedback">{formik.errors.operationType}</div>
					)}
				</Form.Group>
				<Form.Group controlId="formGridProduceName">
					<Form.Label className="font-weight-bold">
						<FormattedMessage {...commonMessages.description} />
					</Form.Label>
					<Form.Control
						name="description"
						onBlur={formik.handleBlur}
						onChange={formik.handleChange}
						placeholder={formatMessage(messages.descriptionPlaceholder)}
						type="text"
						value={formik.values.description}
					/>
				</Form.Group>
				<Form.Row>
					<Form.Group as={Col} controlId="formGridState">
						<Form.Label className="font-weight-bold">
							<FormattedMessage {...commonMessages.date} /> *
						</Form.Label>
						<Form.Row>
							<DatePicker
								inputClassName="datePicker"
								inputPlaceholder={formatMessage(commonMessages.datePlaceholder)}
								onChange={(date) => {
									formik.values.date = String(getDate(date));
									setSelectedOperationDate(date);
									return setSelectedOperationDate;
								}}
								shouldHighlightWeekends
								value={selectedOperationDate}
							/>
						</Form.Row>
						{formik.errors.date && formik.touched.date && (
							<div className="input-feedback">{formik.errors.date} </div>
						)}
					</Form.Group>

					<Form.Group as={Col} controlId="formGridArea">
						<Form.Label className="font-weight-bold">
							<FormattedMessage {...commonMessages.transactions} />
						</Form.Label>
						<Form.Row>
							<Col>
								<Form.Control
									name="transactions"
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									step="0.01"
									type="number"
									value={formik.values.transactions}
								/>
							</Col>
							<Col sm={2}> RON</Col>
						</Form.Row>
					</Form.Group>
				</Form.Row>
				<Form.Row>
					<Form.Group as={Col}>
						<Button className="float-right" type="submit" variant="primary">
							<FormattedMessage {...messages.addOperationButton} />
						</Button>
					</Form.Group>
				</Form.Row>
			</form>
		</>
	);
});
