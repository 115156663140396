import React, { useEffect, useContext } from "react";
import { Jumbotron, Container, Row, Col, Image, Button } from "react-bootstrap";
import { MDBContainer } from "mdbreact";
import { Link, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { FormattedMessage, defineMessages } from "react-intl";

import { LandStoreContext } from "../stores/LandStore";
import "./styles/Maintain.css";
import { Land } from "../types/Land";
import { States } from "../stores/State";
import { convertArea, getDisplayUnit } from "../utils/UnitFormatters";
import { FailedAlert } from "./FailedAlert";
import { Chart } from "./Chart";

interface Props {
	land?: Land;
}

const messages = defineMessages({
	landArea: {
		id: "general.landArea",
		defaultMessage: "Land area",
	},
	cataster: {
		id: "general.cataster",
		defaultMessage: "Cataster number",
	},
	redirect: {
		id: "chart.redirect",
		defaultMessage: "To the planting history",
	},
});

const LandDetail: React.FC<Props> = (props) => {
	const { land } = props;
	return (
		<MDBContainer className="LandDetail cardContainer">
			<Row>
				<Col>
					<Row>
						<span className="NavLinkMargin font-weight-bold">
							<FormattedMessage {...messages.landArea} />:{" "}
						</span>
						<span className="NavLinkMargin">
							{convertArea(land?.area)} {getDisplayUnit(land?.area)}
						</span>
					</Row>
					<Row>
						<span className="NavLinkMargin font-weight-bold">
							<FormattedMessage {...messages.cataster} />:{" "}
						</span>
						<span className="NavLinkMargin">{land?.landId}</span>
					</Row>
				</Col>
			</Row>
		</MDBContainer>
	);
};

export const GeneralInformation: React.FC = observer(() => {
	let { id } = useParams();

	const state = useContext(LandStoreContext);

	useEffect(() => {
		state.getLandById(id);
	}, [id, state]);

	// if state isn't stored in variable, won't rerender the page when state changes
	const landState = state.landState;

	const handleAlert = () => {
		state.resetLandState();
	};

	return (
		<Jumbotron className="NavLinkMargin" jumbotron-fluid>
			<Container>
				<Row>
					<Col sm>
						{state.landState === States.DONE ? (
							<Image
								className="image"
								roundedCircle
								src={require(`../resources/images/landProfiles/${state.land?.imageName}.jpg`)}
							/>
						) : null}
					</Col>
					<Col sm>
						<h1>{state.land?.name}</h1>
						<LandDetail land={state.land} />
						<Link to={`/plantingHistory/${id}`}>
							<Button variant="outline-primary">
								<FormattedMessage {...messages.redirect} />
							</Button>
						</Link>
					</Col>
					<Col sm>
						<Chart />
					</Col>
				</Row>
				<Row className="justify-content-md-center mt-5">
					<Col lg="6" xs>
						{landState === States.ERROR && <FailedAlert onHide={handleAlert} />}
					</Col>
				</Row>
			</Container>
		</Jumbotron>
	);
});
