import { createContext } from "react";
import { observable, action, runInAction } from "mobx";

import {
	OperationDto,
	Operation,
	OperationUpdateDto,
} from "../types/Operation";
import { operationApi } from "../api/OperationApi";
import { States } from "./State";
import { ReportFilteringOptions } from "../types/ReportFilteringOptions";
import { PagingType } from "../types/PagingType";
import { setPagination } from "../utils/PaginationUtils";

class OperationStore {
	@observable
	operations: Operation[] = [];

	@observable
	pagination: PagingType = {
		totalRows: 0,
		pageSize: 10,
		currentPage: 1,
		endPage: 1,
		entitiesOnLastPage: 0,
	};

	@observable
	paginationState: States = States.INITIAL;

	@observable
	operationsState: States = States.INITIAL;

	@observable
	operationsByYear: Operation[] = [];

	@observable
	operationsByYearState: States = States.INITIAL;

	@observable
	newOperationState: States = States.INITIAL;

	@observable
	deleteOperationState: States = States.INITIAL;

	@observable
	updateOperationState: States = States.INITIAL;

	@action.bound
	resetNewOperationState(): void {
		this.newOperationState = States.INITIAL;
	}

	@action.bound
	resetOperationsState(): void {
		this.operationsState = States.INITIAL;
	}

	@action.bound
	resetDeleteOperationState(): void {
		this.deleteOperationState = States.INITIAL;
	}

	@action.bound
	resetUpdateOperationState(): void {
		this.updateOperationState = States.INITIAL;
	}

	@action.bound
	setCurrentPage = (currentPage: number) => {
		this.pagination.currentPage = currentPage;
	};

	@action.bound
	getOperationsPaginated(reportFilteringOptions: ReportFilteringOptions) {
		let fetchPromise: Promise<Operation[]>;

		if (
			reportFilteringOptions.land !== undefined &&
			reportFilteringOptions.year !== undefined
		) {
			fetchPromise = operationApi.getOperationsByYearAndLand(
				reportFilteringOptions.year,
				reportFilteringOptions.land,
				this.pagination.currentPage,
				this.pagination.pageSize
			);
		} else if (
			reportFilteringOptions.land === undefined &&
			reportFilteringOptions.year !== undefined
		) {
			fetchPromise = operationApi.getOperationsByYear(
				reportFilteringOptions.year,
				this.pagination.currentPage,
				this.pagination.pageSize
			);
		} else if (
			reportFilteringOptions.land !== undefined &&
			reportFilteringOptions.year === undefined
		) {
			fetchPromise = operationApi.getOperationsByLand(
				reportFilteringOptions.land,
				this.pagination.currentPage,
				this.pagination.pageSize
			);
		} else {
			fetchPromise = operationApi.getAllOperations(
				this.pagination.currentPage,
				this.pagination.pageSize
			);
		}
		fetchPromise
			.then((operations) => {
				runInAction(() => {
					this.operations = operations;
					this.operationsState = States.DONE;
				});
			})
			.catch((error) => {
				runInAction(() => {
					this.operationsState = States.ERROR;
					console.log(error);
				});
			});
	}

	@action.bound
	getOperations(reportFilteringOptions: ReportFilteringOptions) {
		this.operationsState = States.PENDING;
		this.paginationState = States.PENDING;
		var searchParams = new URLSearchParams();
		if (reportFilteringOptions.year !== undefined) {
			searchParams.append("year", reportFilteringOptions.year.toString());
		}
		if (reportFilteringOptions.land !== undefined) {
			searchParams.append("landId", reportFilteringOptions.land.toString());
		}
		operationApi
			.getTotalOperationNumber(reportFilteringOptions, searchParams.toString())
			.then((number) => {
				runInAction(() => {
					this.pagination = setPagination(
						number,
						this.pagination.pageSize,
						this.pagination.currentPage
					);
					this.paginationState = States.DONE;
					this.operationsState = States.PENDING;
					this.getOperationsPaginated(reportFilteringOptions);
				});
			})
			.catch((error) => {
				console.log(error);
				this.paginationState = States.ERROR;
				this.operationsState = States.ERROR;
			});
	}

	@action.bound
	addNewOperation(operation: OperationDto) {
		this.newOperationState = States.PENDING;
		operationApi
			.postOperation(operation)
			.then(() => {
				runInAction(() => {
					this.newOperationState = States.DONE;
				});
			})
			.catch((error) => {
				runInAction(() => {
					this.newOperationState = States.ERROR;
					console.log(error);
				});
			});
	}

	@action.bound
	deleteOperation(operationId: number, landId: number) {
		this.deleteOperationState = States.PENDING;
		operationApi
			.deleteOperation(operationId)
			.then(() => {
				runInAction(() => {
					this.deleteOperationState = States.DONE;
					if (
						this.pagination.entitiesOnLastPage === 1 &&
						this.pagination.currentPage === this.pagination.endPage
					) {
						this.pagination.currentPage = this.pagination.currentPage - 1;
					}
					const reportFilteringOptions = {
						land: landId,
						year: undefined,
					};
					this.getOperations(reportFilteringOptions);
				});
			})
			.catch((error) => {
				runInAction(() => {
					this.deleteOperationState = States.ERROR;
					console.log(error);
				});
			});
	}

	@action.bound
	updateOperation(
		operation: Partial<OperationUpdateDto>,
		operationId: number,
		landId: number
	) {
		this.updateOperationState = States.PENDING;
		operationApi
			.updateOperation(operation, operationId)
			.then(() => {
				runInAction(() => {
					const reportFilteringOptions = {
						land: landId,
						year: undefined,
					};
					this.getOperations(reportFilteringOptions);
					this.updateOperationState = States.DONE;
				});
			})
			.catch((error) => {
				runInAction(() => {
					this.updateOperationState = States.ERROR;
					console.log(error);
				});
			});
	}
}

export const OperationStoreContext = createContext(new OperationStore());
