import React from "react";

import { ProduceForm } from "../ProduceForm";
import { NewData } from "../navigation/NewData";

export const NewProduce: React.FC = () => {
	return (
		<div>
			<NewData activePage="produce" />
			<ProduceForm />
		</div>
	);
};
