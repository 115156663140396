import React from "react";
import { Col, Container } from "react-bootstrap";

import { PagingType } from "../types/PagingType";
import { PaginationComponent } from "./PaginationComponent";

type Props = {
	pagination: PagingType;
	/**
	 * The base url of the current page.
	 *
	 * E.g. /lands?
	 */
	url: string;
	size?: "sm" | "lg";
};

export const PaginationContainer: React.FC<Props> = ({
	pagination,
	url,
	size,
}) => {
	return (
		<Container>
			<Col md={{ span: 3, offset: 4 }}>
				{pagination.endPage > 1 && (
					<PaginationComponent pagination={pagination} size={size} url={url} />
				)}
			</Col>
		</Container>
	);
};
