import { createContext } from "react";
import { observable, action, runInAction } from "mobx";

import {
	PlantingHistoryDto,
	PlantingHistory,
	PlantingHistoryUpdateDto,
} from "../types/PlantingHistory";
import { plantingHistoryApi } from "../api/PlantingHistoryApi";
import { States } from "./State";
import { PagingType } from "../types/PagingType";
import { setPagination } from "../utils/PaginationUtils";

class PlantingHistoryStore {
	@observable
	plantingHistory: PlantingHistory[] = [];

	@observable
	pagination: PagingType = {
		totalRows: 0,
		pageSize: 10,
		currentPage: 1,
		endPage: 1,
		entitiesOnLastPage: 0,
	};

	@observable
	paginationState: States = States.INITIAL;

	@observable
	plantingHistoryState: States = States.INITIAL;

	@observable
	newPlantingHistoryState: States = States.INITIAL;

	@observable
	updatePlantingHistoryState: States = States.INITIAL;

	@action.bound
	resetNewPlantingHistoryState(): void {
		this.newPlantingHistoryState = States.INITIAL;
	}

	@action.bound
	resetUpdatePlantingHistoryState(): void {
		this.updatePlantingHistoryState = States.INITIAL;
	}

	@action.bound
	resetPlantingHistoryState(): void {
		this.plantingHistoryState = States.INITIAL;
	}

	@action.bound
	setCurrentPage = (currentPage: number) => {
		this.pagination.currentPage = currentPage;
	};

	addNewPlantingHistory(plantingHistory: PlantingHistoryDto) {
		this.newPlantingHistoryState = States.PENDING;
		plantingHistoryApi
			.postPlantingHistory(plantingHistory)
			.then(() => {
				runInAction(() => {
					this.newPlantingHistoryState = States.DONE;
				});
			})
			.catch((error) => {
				runInAction(() => {
					this.newPlantingHistoryState = States.ERROR;
					console.log(error);
				});
			});
	}

	@action.bound
	getPlantingHistoryByLandPaginated(landId: number) {
		plantingHistoryApi
			.getPlantingHistoryByLand(
				landId,
				this.pagination.currentPage,
				this.pagination.pageSize
			)
			.then((plantingHistories) => {
				runInAction(() => {
					this.plantingHistory = plantingHistories;
					this.plantingHistoryState = States.DONE;
				});
			})
			.catch((error) => {
				runInAction(() => {
					this.plantingHistoryState = States.ERROR;
					console.log(error);
				});
			});
	}

	@action.bound
	getPlantingHistoryByLand(landId: number) {
		this.paginationState = States.PENDING;
		plantingHistoryApi
			.getTotalPlantingHistoryNumber(landId)
			.then((number) => {
				runInAction(() => {
					this.pagination = setPagination(
						number,
						this.pagination.pageSize,
						this.pagination.currentPage
					);
					this.paginationState = States.DONE;
					this.plantingHistoryState = States.PENDING;
					this.getPlantingHistoryByLandPaginated(landId);
				});
			})
			.catch((error) => {
				console.log(error);
				this.paginationState = States.ERROR;
				this.plantingHistoryState = States.ERROR;
			});
	}

	@action.bound
	updatePlantingHistory(
		plantingHistory: Partial<PlantingHistoryUpdateDto>,
		plantingHistoryId: number,
		landId: number
	) {
		this.updatePlantingHistoryState = States.PENDING;
		plantingHistoryApi
			.updatePlantingHistory(plantingHistory, plantingHistoryId)
			.then(() => {
				runInAction(() => {
					this.updatePlantingHistoryState = States.DONE;
					this.getPlantingHistoryByLand(landId);
				});
			})
			.catch((error) => {
				runInAction(() => {
					this.updatePlantingHistoryState = States.ERROR;
					console.log(error);
				});
			});
	}
}

export const PlantingHistoryStoreContext = createContext(
	new PlantingHistoryStore()
);
