import {
	OperationDto,
	Operation,
	OperationUpdateDto,
} from "../types/Operation";
import { ReportFilteringOptions } from "../types/ReportFilteringOptions";
import { CONSTANTS } from "./Constants";

class OperationApi {
	async getOperationsByLand(
		landId: number,
		page: number,
		pageSize: number
	): Promise<Operation[]> {
		const response = await fetch(
			`${CONSTANTS.API_URL}/lands/${landId}/operations?page=${page}&pageSize=${pageSize}`,
			{
				method: "GET",
				headers: { "Content-Type": "application/json" },
			}
		);
		return response.json();
	}

	async getTotalOperationNumber(
		reportFilteringOptions: ReportFilteringOptions,
		parameters: string
	): Promise<number> {
		const response = await fetch(
			`${CONSTANTS.API_URL}/operations/total?${parameters}`,
			{
				method: "GET",
				headers: { "Content-Type": "application/json" },
			}
		);
		return response.json();
	}

	async getOperationsByYearAndLand(
		year: number,
		landId: number,
		page: number,
		pageSize: number
	): Promise<Operation[]> {
		const response = await fetch(
			`${CONSTANTS.API_URL}/lands/${landId}/operations?year=${year}&page=${page}&pageSize=${pageSize}`,
			{
				method: "GET",
				headers: { "Content-Type": "application/json" },
			}
		);
		return response.json();
	}

	async getAllOperations(page: number, pageSize: number): Promise<Operation[]> {
		const response = await fetch(
			`${CONSTANTS.API_URL}/operations?page=${page}&pageSize=${pageSize}`,
			{
				method: "GET",
				headers: { "Content-Type": "application/json" },
			}
		);
		return response.json();
	}

	async getOperationsByYear(
		year: number,
		page: number,
		pageSize: number
	): Promise<Operation[]> {
		const response = await fetch(
			`${CONSTANTS.API_URL}/operations?year=${year}&page=${page}&pageSize=${pageSize}`,
			{
				method: "GET",
				headers: { "Content-Type": "application/json" },
			}
		);
		return response.json();
	}

	async postOperation(operation: OperationDto): Promise<void> {
		const response = await fetch(`${CONSTANTS.API_URL}/operations`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(operation),
		});
		if (!response.ok) {
			throw new Error("Creating operation failed");
		}
	}

	async deleteOperation(id: number): Promise<void> {
		await fetch(`${CONSTANTS.API_URL}/operations/${id}`, {
			method: "DELETE",
			headers: { "Content-Type": "application/json" },
		});
	}

	async updateOperation(
		operation: Partial<OperationUpdateDto>,
		operationId: number
	): Promise<void> {
		await fetch(`${CONSTANTS.API_URL}/operations/${operationId}`, {
			method: "PATCH",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(operation),
		});
	}
}

export const operationApi = new OperationApi();
