import { MDBTable, MDBTableHead, MDBTableBody } from "mdbreact";
import { observer } from "mobx-react";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { AiFillEdit } from "react-icons/ai";
import DatePicker, { DayValue } from "react-modern-calendar-datepicker";
import { useHistory, useParams } from "react-router-dom";
import { useIntl, FormattedMessage, FormattedDate } from "react-intl";

import { commonMessages } from "../i18n/CommonMessages";
import { OperationStoreContext } from "../stores/OperationStore";
import { States } from "../stores/State";
import { getDate } from "../utils/DateFormatter";
import { FailedAlert } from "./FailedAlert";
import { setPage } from "../utils/PaginationUtils";
import { useQuery } from "../utils/NavigationHooks";
import { PaginationContainer } from "./PaginationContainer";

export const Operations: React.FC = observer(() => {
	const { id } = useParams();

	const state = useContext(OperationStoreContext);
	// if state isn't stored in variable, won't rerender the page when state changes
	const getOperationsState = state.operationsState;
	const deleteOperationState = state.deleteOperationState;
	const updateOperationState = state.updateOperationState;

	const history = useHistory();
	const page = useQuery().get("page");
	const url = `/general/${id}?`;

	useEffect(() => {
		setPage(state.setCurrentPage, page, history, state.pagination.endPage, url);

		const reportFilteringOptions = {
			land: id,
			year: undefined,
		};

		state.getOperations(reportFilteringOptions);
	}, [history, id, page, state, url]);

	const handleAlert = () => {
		state.resetOperationsState();
	};
	const handleDeleteAlert = () => {
		state.resetDeleteOperationState();
	};
	const handleUpdateAlert = () => {
		state.resetUpdateOperationState();
	};

	const { formatMessage } = useIntl();

	const [showModal, setShowModal] = useState(false);

	const handleClose = () => {
		setShowModal(false);
	};

	const [selectedTableElement, setSelectedTableElement] = useState({
		landId: 0,
		operationTypeId: 0,
		operationId: 0,
		date: "",
		description: "",
		transactions: 0,
		operationName: "",
	});

	const [selectedDescription, setSelectedDescription] = useState<
		string | undefined
	>();

	const handleDescription = (selectedDescription: string) =>
		setSelectedDescription(selectedDescription);

	const [selectedTransactions, setSelectedTransactions] = useState<
		number | undefined
	>();

	const handleTransactions = (selectedTransactions: number) =>
		setSelectedTransactions(selectedTransactions);

	const [selectedDate, setSelectedDate] = useState<DayValue | undefined>();

	const handleDate = (selectedDate: DayValue) => setSelectedDate(selectedDate);

	const onSave = () => {
		let updateValue = {
			date: getDate(selectedDate),
			description: selectedDescription,
			transactions: selectedTransactions,
		};
		state.updateOperation(
			updateValue,
			selectedTableElement.operationId,
			selectedTableElement.landId
		);
		setShowModal(false);
	};

	const data = {
		columns: [
			{
				label: formatMessage(commonMessages.operationTable),
				field: "operation",
				sort: "asc",
				width: 200,
			},
			{
				label: formatMessage(commonMessages.description),
				field: "description",
				sort: "asc",
				width: 400,
			},
			{
				label: formatMessage(commonMessages.date),
				field: "date",
				sort: "asc",
				width: 100,
			},
			{
				label: formatMessage(commonMessages.transactions),
				field: "transactions",
				sort: "asc",
				width: 100,
			},
			{
				label: formatMessage(commonMessages.delete),
				field: "delete",
				sort: "asc",
				width: 30,
			},
			{
				label: formatMessage(commonMessages.edit),
				field: "edit",
				sort: "asc",
				width: 30,
			},
		],
		rows: state.operations.map((operation) => {
			return {
				operation: operation.operationType.name,
				description: operation.description,
				date: <FormattedDate value={operation.date} />,
				transactions: operation.transactions,
				delete: (
					<Button
						onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
							event.preventDefault();
							state.deleteOperation(operation.id, operation.land.id);
						}}
						variant="outline-primary"
					>
						<FormattedMessage {...commonMessages.delete} />
					</Button>
				),
				edit: (
					<Button
						onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
							event.preventDefault();
							setShowModal(true);
							setSelectedTableElement({
								landId: operation.land.id,
								operationTypeId: operation.operationType.id,
								operationId: operation.id,
								operationName: operation.operationType.name,
								date: String(operation.date),
								description: operation.description,
								transactions: operation.transactions,
							});
						}}
						size="sm"
						variant="outline-primary"
					>
						<AiFillEdit />
					</Button>
				),
			};
		}),
	};

	return (
		<div>
			<Container>
				<Row className="justify-content-md-center mt-5">
					<Col lg="6" xs>
						{getOperationsState === States.ERROR && (
							<FailedAlert onHide={handleAlert} />
						)}
						{deleteOperationState === States.ERROR && (
							<FailedAlert onHide={handleDeleteAlert} />
						)}
						{updateOperationState === States.ERROR && (
							<FailedAlert onHide={handleUpdateAlert} />
						)}
					</Col>
				</Row>
			</Container>

			<MDBTable btn className="marginTable" fixed striped>
				<MDBTableHead columns={data.columns} />
				<MDBTableBody rows={data.rows} />
			</MDBTable>

			<PaginationContainer pagination={state.pagination} url={url} />

			<Modal onHide={handleClose} show={showModal}>
				<Modal.Header closeButton>
					<Modal.Title>
						<FormattedMessage
							{...commonMessages.edit}
							values={{
								name: selectedTableElement.operationName,
							}}
						/>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col>
							<h5>
								<FormattedMessage {...commonMessages.description} />:
							</h5>
						</Col>
						<Col>
							<input
								defaultValue={selectedTableElement.description}
								onChange={(event) => handleDescription(event.target.value)}
								type="string"
								value={selectedDescription}
							/>
						</Col>
					</Row>

					<Row>
						<Col>
							<h5>
								<FormattedMessage {...commonMessages.date} />:
							</h5>
						</Col>

						<Col>
							<DatePicker
								inputClassName="datePicker"
								inputPlaceholder={formatMessage(commonMessages.datePlaceholder)}
								onChange={handleDate}
								shouldHighlightWeekends
								value={selectedDate}
							/>
						</Col>
					</Row>

					<Row>
						<Col>
							<h5>
								<FormattedMessage {...commonMessages.transactions} />:
							</h5>
						</Col>
						<Col>
							<input
								defaultValue={selectedTableElement.transactions}
								onChange={(event) =>
									handleTransactions(Number(event.target.value))
								}
								type="number"
								value={selectedTransactions}
							/>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={handleClose} variant="secondary">
						<FormattedMessage {...commonMessages.closeButton} />
					</Button>
					<Button onClick={onSave} variant="primary">
						<FormattedMessage {...commonMessages.save} />
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
});
