import React from "react";
import "../styles/LoadingPage.css";

export const LoadingPage: React.FC = () => {
	return (
		<img
			alt="Loading Page"
			className="center"
			src={require(`../../resources/images/loading/load.gif`)}
		/>
	);
};
