import React from "react";
import { Row, Col } from "react-bootstrap";

import { MaintainNavigation } from "../navigation/MaintainNavigation";
import { PlantingHistoryList } from "../PlantingHistoryList";
import { PlantingHistoryForm } from "../PlantingHistoryForm";

export const AddNewPlantingHistoryPage: React.FC = () => {
	return (
		<div>
			<Row>
				<Col sm={3}>
					<MaintainNavigation activePage="plantingHistory" />
				</Col>
				<Col sm={9}>
					<PlantingHistoryForm />
					<PlantingHistoryList />
				</Col>
			</Row>
		</div>
	);
};
