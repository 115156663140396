import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import * as Yup from "yup";
import { useFormik } from "formik";
import { defineMessages, useIntl, FormattedMessage } from "react-intl";
import { Container, Row, Col } from "react-bootstrap";

import { formStyles } from "./styles/Styles";
import { OperationTypeStoreContext } from "../stores/OperationTypeStore";
import { commonMessages } from "../i18n/CommonMessages";
import { States } from "../stores/State";
import { FailedAlert } from "./FailedAlert";
import { SuccesfulAlert } from "./SuccesfulAlert";

const messages = defineMessages({
	operationTypeName: {
		id: "operationTypeForm.operationTypeName",
		defaultMessage: "Operation type name",
	},
	operationTypeButton: {
		id: "operationTypeForm.operationTypeButton",
		defaultMessage: "Add new operation type",
	},
	operationTypePlaceholder: {
		id: "operationTypeForm.operationTypePlaceholder",
		defaultMessage: "Enter your operations name",
	},
});

export const OperationTypeForm = observer(() => {
	const state = useContext(OperationTypeStoreContext);
	// if state isn't stored in variable, won't rerender the page when state changes
	const newOperationTypeState = state.newOperationTypeState;

	const { formatMessage } = useIntl();

	const handleAlert = () => {
		state.resetNewOperationTypeState();
	};

	const formik = useFormik({
		initialValues: {
			name: "",
		},
		onSubmit: (values) => {
			state.addNewOperationType(values);
		},
		validationSchema: Yup.object().shape({
			name: Yup.string()
				.min(
					3,
					formatMessage(commonMessages.tooShortValidation, {
						entity: formatMessage(messages.operationTypeName),
						number: 3,
					})
				)
				.required(formatMessage(commonMessages.required)),
		}),
	});

	const resetForm = formik.resetForm;
	const dirty = formik.dirty;
	useEffect(() => {
		if (newOperationTypeState === States.DONE && dirty) {
			resetForm();
		}
	}, [newOperationTypeState, resetForm, dirty]);

	return (
		<>
			<Container>
				<Row className="justify-content-md-center mt-5">
					<Col lg="6" xs>
						{newOperationTypeState === States.DONE && (
							<SuccesfulAlert onHide={handleAlert} />
						)}
						{newOperationTypeState === States.ERROR && (
							<FailedAlert onHide={handleAlert} />
						)}
					</Col>
				</Row>
			</Container>
			<form action="" onSubmit={formik.handleSubmit} style={formStyles.form}>
				<Form.Group controlId="formGridProduceName">
					<Form.Label className="font-weight-bold">
						<FormattedMessage {...messages.operationTypeName} />
					</Form.Label>
					<Form.Control
						name="name"
						onBlur={formik.handleBlur}
						onChange={formik.handleChange}
						placeholder={formatMessage(messages.operationTypePlaceholder)}
						type="text"
						value={formik.values.name}
					/>
					{formik.errors.name && formik.touched.name && (
						<div className="input-feedback">{formik.errors.name}</div>
					)}
				</Form.Group>

				<Form.Group>
					<Button
						className="float-right"
						style={formStyles.form}
						type="submit"
						variant="primary"
					>
						<FormattedMessage {...messages.operationTypeButton} />
					</Button>
				</Form.Group>
			</form>
		</>
	);
});
