import {
	PlantingHistoryDto,
	PlantingHistory,
	PlantingHistoryUpdateDto,
} from "../types/PlantingHistory";
import { CONSTANTS } from "./Constants";

class PlantingHistoryApi {
	async postPlantingHistory(
		plantingHistory: PlantingHistoryDto
	): Promise<void> {
		const response = await fetch(`${CONSTANTS.API_URL}/plantinghistory`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(plantingHistory),
		});
		if (!response.ok) {
			throw new Error("Creating planting history failed");
		}
	}

	async getPlantingHistoryByLand(
		landId: number,
		page: number,
		pageSize: number
	): Promise<PlantingHistory[]> {
		const response = await fetch(
			`${CONSTANTS.API_URL}/lands/${landId}/plantingHistory?page=${page}&pageSize=${pageSize}`,
			{
				method: "GET",
				headers: { "Content-Type": "application/json" },
			}
		);
		return response.json();
	}

	async getTotalPlantingHistoryNumber(landId: number): Promise<number> {
		const response = await fetch(
			`${CONSTANTS.API_URL}/lands/${landId}/plantingHistory/total`,
			{
				method: "GET",
				headers: { "Content-Type": "application/json" },
			}
		);
		return response.json();
	}

	async updatePlantingHistory(
		plantingHistory: Partial<PlantingHistoryUpdateDto>,
		plantingHistoryId: number
	): Promise<void> {
		const response = await fetch(
			`${CONSTANTS.API_URL}/plantingHistory/${plantingHistoryId}`,
			{
				method: "PATCH",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(plantingHistory),
			}
		);
		if (!response.ok) {
			throw new Error("Updating planting history failed");
		}
	}
}

export const plantingHistoryApi = new PlantingHistoryApi();
