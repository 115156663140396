import { LoginDto } from "../types/User";
import { CONSTANTS } from "./Constants";
import { IntrospectDto } from "../types/IntrospectDto";

class LogInApi {
	async authenticate(user: LoginDto): Promise<void> {
		const response = await fetch(`${CONSTANTS.API_URL}/login`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(user),
		});
		if (!response.ok) {
			throw new Error("Login failed");
		} else {
			return response.json();
		}
	}

	async logout(): Promise<void> {
		await fetch(`${CONSTANTS.API_URL}/logout`, {
			method: "Get",
		});
	}

	async introspect(): Promise<IntrospectDto> {
		const response = await fetch(`${CONSTANTS.API_URL}/introspect`, {
			method: "Get",
		});

		return response.json();
	}
}

export const logInApi = new LogInApi();
