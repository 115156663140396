import React, { useContext } from "react";
import { observer } from "mobx-react";

import { Routes } from "./components/Routes";
import { NavigationBar } from "./components/navigation/NavigationBar";
import { Provider } from "./i18n/Provider";
import { LanguageStoreContext } from "./stores/LanguageStore";

const App: React.FC = observer(() => {
	const languageState = useContext(LanguageStoreContext);

	return (
		<Provider locale={languageState.language}>
			<div className="App">
				<NavigationBar languageState={languageState} />
				<Routes />
			</div>
		</Provider>
	);
});

// eslint-disable-next-line import/no-default-export
export default App;
