import React from "react";
import { Row, Col } from "react-bootstrap";

import { OperationForm } from "../OperationForm";
import { MaintainNavigation } from "../navigation/MaintainNavigation";

export const AddNewOperationPage: React.FC = () => {
	return (
		<div>
			<Row>
				<Col sm={3}>
					<MaintainNavigation activePage="addOperation" />
				</Col>
				<Col sm={9}>
					<OperationForm />
				</Col>
			</Row>
		</div>
	);
};
