import React, { useState, useContext } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { defineMessages, FormattedMessage } from "react-intl";

import { Land } from "./../types/Land";
import { LandStoreContext } from "../stores/LandStore";
import { LandForm } from "./LandForm";
import { getDisplayUnit, convertArea } from "./../utils/UnitFormatters";
import { commonMessages } from "../i18n/CommonMessages";

type Props = {
	land: Land;
};

const messages = defineMessages({
	deleteComfirmation: {
		id: "land.deleteComfirmation",
		defaultMessage: "Delete confirmation",
	},
	deleteConfirmationModal: {
		id: "land.deleteConfirmationModal",
		defaultMessage:
			"The land {name}, with {landId} and {area} {unit} area will be deleted.",
	},
	maintain: {
		id: "land.maintain",
		defaultMessage: "Maintain",
	},
	update: {
		id: "land.update",
		defaultMessage: "You can update your land here",
	},
});

export const LandCard: React.FC<Props> = observer(({ land }) => {
	const state = useContext(LandStoreContext);

	const [showDelete, setShowDelete] = useState(false);
	const [showUpdate, setShowUpdate] = useState(false);

	const handleClickDelete = () => {
		state.deleteLands(land.id);
	};
	const handleCloseDelete = () => setShowDelete(false);
	const handleShowDelete = () => setShowDelete(true);

	const handleCloseUpdate = () => setShowUpdate(false);
	const handleShowUpdate = () => setShowUpdate(true);

	return (
		<>
			{/*delete confirmation in a modal*/}
			<Modal
				backdrop="static"
				keyboard={false}
				onHide={handleCloseDelete}
				show={showDelete}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<FormattedMessage {...messages.deleteComfirmation} />
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<FormattedMessage
						{...messages.deleteConfirmationModal}
						values={{
							name: land.name,
							landId: land.landId,
							area: convertArea(land.area),
							unit: getDisplayUnit(land.area),
						}}
					/>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={handleCloseDelete} variant="secondary">
						<FormattedMessage {...commonMessages.undo} />
					</Button>
					<Button onClick={handleClickDelete} variant="primary">
						<FormattedMessage {...commonMessages.goAhead} />
					</Button>
				</Modal.Footer>
			</Modal>

			{/* edit form in a modal*/}
			<Modal onHide={handleCloseUpdate} show={showUpdate}>
				<Modal.Header closeButton>
					<Modal.Title>
						<FormattedMessage {...messages.update} />
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<LandForm land={land} />
				</Modal.Body>
			</Modal>

			{/* card what displays a land*/}
			<Col>
				<Card className="mt-4 mb-4">
					<Card.Img
						src={require(`../resources/images/landProfiles/${land.imageName}.jpg`)}
						variant="top"
					/>
					<Card.Body>
						<Card.Title>{land.name}</Card.Title>
						<Card.Text>
							{convertArea(land.area)} {getDisplayUnit(land.area)}
						</Card.Text>
						<Link to={`/general/${land.id}`}>
							<Button style={{ margin: "2px" }} variant="info">
								<FormattedMessage {...messages.maintain} />
							</Button>
						</Link>
						<Button
							onClick={handleShowUpdate}
							style={{ margin: "2px" }}
							variant="outline-primary"
						>
							<FormattedMessage {...commonMessages.edit} />
						</Button>
						<Button
							onClick={handleShowDelete}
							style={{ margin: "2px" }}
							variant="outline-primary"
						>
							<FormattedMessage {...commonMessages.delete} />
						</Button>
					</Card.Body>
				</Card>
			</Col>
		</>
	);
});
