export type Produce = {
	id: number;
	name: string;
	code: number;
	category: ProduceCategory;
};

export type ProduceDto = {
	name: string;
	code: number;
	category: ProduceCategory;
};

export enum ProduceCategory {
	RowCrop = 1,
	Legumes = 2,
	GrassesAndCereals = 3,
	GreenManure = 4,
}
