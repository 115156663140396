import { Button, Row, Col } from "react-bootstrap";
import React from "react";
import { Link } from "react-router-dom";
import "../styles/LandForm.css";
import { defineMessages, FormattedMessage } from "react-intl";

type Props = {
	activePage?: string;
};

const messages = defineMessages({
	newLand: {
		id: "newData.newLand",
		defaultMessage: "New land",
	},
	newProduce: {
		id: "newData.newProduce",
		defaultMessage: "New produce",
	},
	newOperationType: {
		id: "newData.newOperationType",
		defaultMessage: "New operation type",
	},
});

export const NewData: React.FC<Props> = ({ activePage }) => {
	return (
		<Row>
			<Col className="newLandButton">
				<Link to="/newland">
					<Button
						block
						variant={activePage === "newland" ? "primary" : "secondary"}
					>
						<FormattedMessage {...messages.newLand} />
					</Button>
				</Link>
			</Col>
			{"  "}
			<Col className="newProduce">
				<Link to="/produce">
					<Button
						block
						variant={activePage === "produce" ? "primary" : "secondary"}
					>
						<FormattedMessage {...messages.newProduce} />
					</Button>
				</Link>
			</Col>
			{"  "}
			<Col className="newOperationType">
				<Link to="/operationType">
					<Button
						block
						variant={activePage === "operationType" ? "primary" : "secondary"}
					>
						<FormattedMessage {...messages.newOperationType} />
					</Button>
				</Link>
			</Col>
		</Row>
	);
};
