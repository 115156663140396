import React, { useState, useContext, useEffect } from "react";
import { observer } from "mobx-react";
import { Col, Form, Row, Button, Container } from "react-bootstrap";
import Select, { ValueType } from "react-select";
import {
	defineMessages,
	FormattedMessage,
	useIntl,
	FormattedDate,
} from "react-intl";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import { useHistory } from "react-router-dom";

import "./styles/Home.css";
import { FinancialReportStoreContext } from "../stores/FinancialReportStore";
import { OperationStoreContext } from "../stores/OperationStore";
import { LandStoreContext } from "../stores/LandStore";
import { commonMessages } from "../i18n/CommonMessages";
import { States } from "../stores/State";
import { FailedAlert } from "./FailedAlert";
import { setPage } from "../utils/PaginationUtils";
import { useQuery } from "../utils/NavigationHooks";
import { PaginationContainer } from "./PaginationContainer";

const messages = defineMessages({
	filterByYear: {
		id: "home.filterByYear",
		defaultMessage: "Filter by year",
	},
	filterByLand: {
		id: "home.filterByLand",
		defaultMessage: "Filter by land",
	},
	annualStatement: {
		id: "home.annualStatement",
		defaultMessage: "Annual statement",
	},
	expense: {
		id: "home.expense",
		defaultMessage: "Expense",
	},
	income: {
		id: "home.income",
		defaultMessage: "Income",
	},
	finalBalance: {
		id: "home.finalBalance",
		defaultMessage: "Final balance",
	},
	showDetailStatement: {
		id: "home.showDetailStatement",
		defaultMessage: "Show detail statement",
	},
	hideDetailStatement: {
		id: "home.hideDetailStatement",
		defaultMessage: "Hide detail statement",
	},
	landName: {
		id: "home.landName",
		defaultMessage: "Land name",
	},
	operationType: {
		id: "home.operationType",
		defaultMessage: "Operation type",
	},
	plantingDate: {
		id: "home.plantingDate",
		defaultMessage: "Planting date",
	},
	description: {
		id: "home.description",
		defaultMessage: "Description",
	},
	balance: {
		id: "home.balance",
		defaultMessage: "Balance",
	},
	format: {
		id: "home.format",
		defaultMessage: "Format",
	},
	all: {
		id: "home.all",
		defaultMessage: "All",
	},
	print: {
		id: "home.print",
		defaultMessage: "Print or save to pdf the financial report",
	},
	printYear: {
		id: "home.printYear",
		defaultMessage: "Year :",
	},
	printLand: {
		id: "home.printLand",
		defaultMessage: "Land: ",
	},
});

type OptionType = { label: string; value: number };
type LandOptionType = { label: string; value: string; key: number };

export const HomePage: React.FC = observer(() => {
	const state = useContext(FinancialReportStoreContext);
	const detailedState = useContext(OperationStoreContext);
	const landState = useContext(LandStoreContext);

	// if state isn't stored in variable, won't rerender the page when state changes
	const getLandsState = landState.landsState;
	const getFinancialReportState = state.financialReportState;
	const getOperationsState = detailedState.operationsState;

	const [selectedYear, setSelectedYear] = useState(-1);
	const [selectedLandId, setSelectedLandId] = useState(-1);

	const [showTable, setShowTable] = useState(true);

	const toggleShowTable = () => {
		setShowTable(!showTable);
	};
	const print = () => {
		window.print();
	};
	useEffect(() => {
		landState.getAllLands();
	}, [landState]);

	useEffect(() => {
		const reportFilteringOptions = {
			land: selectedLandId === -1 ? undefined : selectedLandId,
			year: selectedYear === -1 ? undefined : selectedYear,
		};
		state.getReport(reportFilteringOptions);
	}, [selectedLandId, selectedYear, state]);

	const history = useHistory();
	const page = useQuery().get("page");
	const url = `?`;

	useEffect(() => {
		setPage(
			detailedState.setCurrentPage,
			page,
			history,
			detailedState.pagination.endPage,
			url
		);

		const reportFilteringOptions = {
			land: selectedLandId === -1 ? undefined : selectedLandId,
			year: selectedYear === -1 ? undefined : selectedYear,
		};

		detailedState.getOperations(reportFilteringOptions);
	}, [url, page, history, selectedYear, selectedLandId, detailedState]);

	const handleAlert = () => {
		landState.resetLandState();
	};
	const handleGetFinancialReportAlert = () => {
		state.resetFinancialReportState();
	};
	const handleGetOperationsAlert = () => {
		detailedState.resetOperationsState();
	};

	const { formatMessage } = useIntl();

	const getOptions = () => {
		const options = [{ value: -1, label: formatMessage(messages.all) }];
		const year = new Date().getFullYear();
		for (var i = year; i > year - 20; i--) {
			options.push({
				value: i,
				label: i.toString(),
			});
		}
		return options;
	};

	const options = getOptions();

	const lands = [{ value: "all", label: formatMessage(messages.all), key: -1 }];

	landState.lands.forEach((land) =>
		lands.push({ value: land.name, label: land.name, key: land.id })
	);

	const setTableClassName = (showTable: boolean) => {
		if (!showTable) {
			return "visibleTable marginTable visiblePrint";
		}
		return "notVisibleTable marginTable visiblePrint";
	};
	const printYear = (year: number) => {
		if (year !== -1) {
			return year;
		} else {
			return formatMessage(messages.all);
		}
	};

	const columns = [
		{
			label: "#",
			field: "id",
			sort: "asc",
		},
		{
			label: formatMessage(messages.landName),
			field: "landName",
			sort: "asc",
		},
		{
			label: formatMessage(messages.operationType),
			field: "operationType",
			sort: "asc",
		},
		{
			label: formatMessage(commonMessages.date),
			field: "plantingDate",
			sort: "asc",
		},
		{
			label: formatMessage(commonMessages.description),
			field: "description",
			sort: "asc",
		},
		{
			label: formatMessage(messages.income),
			field: "income",
			sort: "asc",
		},
		{
			label: formatMessage(messages.expense),
			field: "expense",
			sort: "asc",
		},
	];

	const rows = detailedState.operations.map((operations, index) => {
		return {
			id: index,
			landName: operations.land.name,
			operationType: operations.operationType.name,
			plantingDate: <FormattedDate value={operations.date} />,
			description: operations.description,
			income: operations.transactions > 0 ? operations.transactions : "",
			expense:
				operations.transactions > 0 ? "" : Math.abs(operations.transactions),
		};
	});

	return (
		<div>
			<Container>
				<Row className="justify-content-md-center mt-5">
					<Col lg="6" xs>
						{getLandsState === States.ERROR && (
							<FailedAlert onHide={handleAlert} />
						)}
						{getFinancialReportState === States.ERROR && (
							<FailedAlert onHide={handleGetFinancialReportAlert} />
						)}
						{getOperationsState === States.ERROR && (
							<FailedAlert onHide={handleGetOperationsAlert} />
						)}
					</Col>
				</Row>
			</Container>
			<h3 className="marginJumbotron">
				<FormattedMessage {...messages.annualStatement} />
			</h3>
			<Row>
				<Col className="marginExpense">
					<label className="font-weight-bold printHidden">
						<FormattedMessage {...messages.filterByYear} />
					</label>

					<Select
						className="printHidden"
						onChange={(selectedOption: ValueType<OptionType>) => {
							const value = (selectedOption as OptionType).value;
							setSelectedYear(value);
						}}
						options={options}
						value={options.find((obj) => obj.value === selectedYear)}
					/>
				</Col>
				<Col className="marginExpense printOnly">
					<label className="font-weight-bold">
						<FormattedMessage {...messages.printYear} />{" "}
						{printYear(selectedYear)}
					</label>
				</Col>

				<Col className="marginExpense">
					<label className="font-weight-bold printHidden">
						<FormattedMessage {...messages.filterByLand} />
					</label>

					<Select
						className="printHidden"
						onChange={(selectedOption: ValueType<LandOptionType>) => {
							setSelectedLandId((selectedOption as LandOptionType).key);
						}}
						options={lands}
						value={lands.find((obj) => obj.key === selectedLandId)}
					/>
				</Col>

				<Col className="marginExpense printOnly">
					<label className="font-weight-bold">
						<FormattedMessage {...messages.printLand} />
						{lands.find((land) => land.key === selectedLandId)?.label ?? ""};
					</label>
				</Col>
				<Form>
					<Form.Row>
						<Form.Group as={Col} className="marginExpense">
							<Form.Label className="font-weight-bold">
								<FormattedMessage {...messages.expense} />
							</Form.Label>
							<Form.Control
								disabled
								name="expense"
								type="text"
								value={
									state.financialReport?.expense
										? Math.abs(state.financialReport?.expense)
										: 0
								}
							/>
						</Form.Group>
						<Form.Group as={Col} className="marginIncome">
							<Form.Label className="font-weight-bold">
								<FormattedMessage {...messages.income} />
							</Form.Label>
							<Form.Control
								disabled
								name="income"
								type="text"
								value={
									state.financialReport?.income
										? Math.abs(state.financialReport?.income)
										: 0
								}
							/>
						</Form.Group>
						<Form.Group as={Col} className="marginSearchButton">
							<Form.Label className="font-weight-bold">
								<FormattedMessage {...messages.finalBalance} />
							</Form.Label>
							<Form.Control
								disabled
								name="balance"
								type="text"
								value={
									state.financialReport?.balance
										? Math.abs(state.financialReport?.balance)
										: 0
								}
							/>
						</Form.Group>
					</Form.Row>
				</Form>
			</Row>

			<div className="printHidden">
				<Button
					className="marginShowButton float-right"
					onClick={toggleShowTable}
				>
					{showTable ? (
						<FormattedMessage {...messages.showDetailStatement} />
					) : (
						<FormattedMessage {...messages.hideDetailStatement} />
					)}
				</Button>

				<Button className="marginShowButton float-right" onClick={print}>
					<FormattedMessage {...messages.print} />
				</Button>
			</div>

			<div className={setTableClassName(showTable)}>
				<MDBTable btn>
					<MDBTableHead columns={columns} />
					<MDBTableBody rows={rows} />
				</MDBTable>

				<PaginationContainer pagination={detailedState.pagination} url={url} />
			</div>
		</div>
	);
});
