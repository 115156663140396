import { FinancialReport } from "../types/FinancialReport";
import { CONSTANTS } from "./Constants";

class FinancialReportApi {
	async getReportForYear(year: number): Promise<FinancialReport> {
		const response = await fetch(
			`${CONSTANTS.API_URL}/financialReport/${year}`,
			{
				method: "GET",
				headers: { "Content-Type": "application/json" },
			}
		);
		return response.json();
	}

	async getReportForLand(land: number): Promise<FinancialReport> {
		const response = await fetch(
			`${CONSTANTS.API_URL}/lands/${land}/financialReports`,
			{
				method: "GET",
				headers: { "Content-Type": "application/json" },
			}
		);
		return response.json();
	}

	async getReportForYearAndLand(
		year: number,
		land: number
	): Promise<FinancialReport> {
		const response = await fetch(
			`${CONSTANTS.API_URL}/lands/${land}/financialReports?year=${year}`,
			{
				method: "GET",
				headers: { "Content-Type": "application/json" },
			}
		);
		return response.json();
	}

	async getReportForAll(): Promise<FinancialReport> {
		const response = await fetch(`${CONSTANTS.API_URL}/financialReport`, {
			method: "GET",
			headers: { "Content-Type": "application/json" },
		});
		return response.json();
	}
}

export const financialReportApi = new FinancialReportApi();
