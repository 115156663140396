import React from "react";

import { HomePage } from "../HomePage";

export const Home: React.FC = () => {
	return (
		<div>
			<HomePage />
		</div>
	);
};
